import * as React from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { UseUserState } from 'context/UserContext';

/* iconos redes sociales */
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import TelegramIcon from '@mui/icons-material/Telegram';
import InstagramIcon from '@mui/icons-material/Instagram';
import LanguageIcon from '@mui/icons-material/Language';

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const redes = [
    { title: 'Whatsapp', id: 'whatsapp' },
    { title: 'Facebook', id: 'facebook' },
    { title: 'Telegram', id: 'telegram' },
    { title: 'Instagram', id: 'instagram' },
    { title: 'WebChat', id: 'webchat' }
];

export default function FiltroBot({ value, setVal }) {
    const { datos, listaBotRedes } = UseUserState();

    const RSSIcon = (nombre) => {
        let icon = null;
        if (nombre === 'Facebook') {
            icon = <FacebookIcon style={{ backgroundColor: '#FAFAFA', color: '#395692', borderRadius: '2px' }} />;
        } else if (nombre === 'Whatsapp') {
            icon = <WhatsAppIcon style={{ color: '#FAFAFA', backgroundColor: '#42be52', borderRadius: '2px' }} />;
        } else if (nombre === 'Instagram') {
            icon = (
                <InstagramIcon
                    style={{
                        color: '#FAFAFA',
                        background: 'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)',
                        borderRadius: '2px'
                    }}
                />
            );
        } else if (nombre === 'Telegram') {
            icon = <TelegramIcon style={{ color: '#FAFAFA', backgroundColor: '#32a7d9', borderRadius: '2px' }} />;
        } else if (nombre === 'WebChat') {
            icon = <LanguageIcon style={{ color: '#FAFAFA', backgroundColor: '#056179', borderRadius: '2px' }} />;
        }

        return icon;
    };

    return (
        <Stack spacing={3}>
            <Autocomplete
                multiple
                value={value}
                id="tags-outlined"
                options={redes}
                getOptionLabel={(option) => option.title}
                filterSelectedOptions
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => <Chip avatar={RSSIcon(option.title)} {...getTagProps({ index })} />)
                }
                renderInput={(params) => <TextField {...params} placeholder="Red" />}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, newValue) => setVal('red', newValue)}
            />
        </Stack>
    );
}
