import { db } from 'services/firebase';
import { MLog } from 'services/FuncionesDev';

function infoUser(red, dato) {
    switch (red) {
        case 'facebook':
            return { user: dato.atributos.fb_username };
        case 'whatsapp':
            return { user: dato.atributos.ws_username };
        case 'telegram':
            return { user: dato.atributos.tg_nombre };
        case 'instagram':
            return { user: dato.atributos.ig_username };
        default:
            return { user: dato.atributos.wb_username };
    }
}

export function ModificarDatos(modificar, listaDatos, listaActual, bandejaActual, tableHashUser, usuarioActual) {
    // buscar si existe el dato
    if (tableHashUser.get(modificar.id) === undefined) {
        // nuevos datos
        tableHashUser.set(modificar.id, modificar);
        listaDatos.unshift({ id: modificar.id });
        if (
            (bandejaActual.id === 0 && modificar.dato.atributos.chatcenter === true && modificar.dato.atributos.bandeja === undefined) ||
            (bandejaActual.id === 1 &&
                (modificar.dato.atributos.chatcenter === false || modificar.dato.atributos.chatcenter === undefined)) ||
            (modificar.dato.atributos.bandeja !== undefined &&
                modificar.dato.atributos.bandeja === bandejaActual.title &&
                modificar.dato.atributos.chatcenter === true)
        ) {
            listaActual.unshift({ id: modificar.id });
        }
    } else {
        // usuario ya existe
        tableHashUser.set(modificar.id, modificar);
        // si es el usuario actual se actualiza
        if (modificar.id === usuarioActual.id) {
            usuarioActual.dato = modificar.dato;
        }
        // comprobar si el nuevo tiene que estar en la lista a mostrar
        if (
            (bandejaActual.id === 0 && modificar.dato.atributos.chatcenter === true && modificar.dato.atributos.bandeja === undefined) ||
            (bandejaActual.id === 1 &&
                (modificar.dato.atributos.chatcenter === false || modificar.dato.atributos.chatcenter === undefined)) ||
            (modificar.dato.atributos.bandeja !== undefined &&
                modificar.dato.atributos.bandeja === bandejaActual.title &&
                modificar.dato.atributos.chatcenter === true)
        ) {
            // si no esta en la lista a mostrar se agrega
            const indexMod = listaActual.findIndex((item) => item.id === modificar.id);
            if (indexMod === -1) {
                listaActual.unshift({ id: modificar.id });
            }
        } else {
            // se busca si esta para eliminarla
            const indexMod = listaActual.findIndex((item) => item.id === modificar.id);
            if (indexMod > -1) {
                listaActual.splice(indexMod, 1);
                // si es usuario actual, el usuario actual se coloca en ''
                if (modificar.id === usuarioActual.id) {
                    usuarioActual = '';
                }
            }
        }
    }
    return usuarioActual;
}

export function NuevosDatos(modificar, listaDatos, listaActual, bandejaActual) {
    // Comprobar bandeja actual
    if (
        (bandejaActual.id === 0 && modificar.dato.atributos.chatcenter === true && modificar.dato.atributos.bandeja === undefined) ||
        (bandejaActual.id === 1 && (modificar.dato.atributos.chatcenter === false || modificar.dato.atributos.chatcenter === undefined)) ||
        (modificar.dato.atributos.bandeja !== undefined &&
            modificar.dato.atributos.bandeja === bandejaActual.title &&
            modificar.dato.atributos.chatcenter === true)
    ) {
        listaDatos.unshift(modificar);
        listaActual.unshift(modificar);
    } else {
        listaDatos.unshift(modificar);
    }
}

export function ActivarContadores(datos, usuarioActual, nuevoActual) {
    // colocar en el usuario actual         leido: false y msg_noleido: 0 para contar las notificaciones
    if (usuarioActual !== '') {
        db.update(
            `cliente_chat/${datos.id_empresa}/${usuarioActual.info.red}/${usuarioActual.info.idbot}/${usuarioActual.info.id}/atributos`,
            {
                leido: false,
                msg_noleido: 0
            }
        );
    }

    if (nuevoActual !== '') {
        db.update(`cliente_chat/${datos.id_empresa}/${nuevoActual.info.red}/${nuevoActual.info.idbot}/${nuevoActual.info.id}/atributos`, {
            leido: true,
            msg_noleido: 0
        });
    }

    /// eliminar de la lista de usuarios actuales
    if (nuevoActual !== '' && nuevoActual.dato.atributos.bandeja !== undefined) {
        db.set(
            `bandeja_usuario/${datos.id_empresa}/${nuevoActual.info.red}/${nuevoActual.info.idbot}/${nuevoActual.dato.atributos.bandeja}/${nuevoActual.info.id}/`,
            null
        );
    }
}

export function Preferencias(dispach, red, bot, user, datos, MostrarPreferencias) {
    db.set(`usuarios/${user.id}/configuraciones/ChatCenter`, {
        bots: bot,
        redes: red
    });
    MostrarPreferencias(dispach, false);
}
