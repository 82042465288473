import { db, GetStorage, RefStorage, UploadBytesResumable, GetDownloadURL, DeleteObject } from 'services/firebase';
import { EnviarMensaje } from './mensajes';
import { MLog } from 'services/FuncionesDev';

function FileExtension(filename) {
    return /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0] : undefined;
}

function FileTipo(tipo) {
    if (tipo === 0) {
        return 'image';
    }
    if (tipo === 1) {
        return 'file';
    }
    if (tipo === 2) {
        return 'audio';
    }
    return 'video';
}

function GetDireccion(tipo) {
    if (tipo === 0) {
        return 'imagen';
    }
    if (tipo === 1) {
        return 'archivos';
    }
    if (tipo === 2) {
        return 'audios';
    }
    return 'videos';
}

export function CargarArchivos(
    datos,
    user,
    archivo,
    tipo,
    setProgress,
    setAlerta,
    setSubiendo,
    setUploadFile,
    setArchivosNuevos,
    nombreArchivo,
    LoadMultimedia,
    dispach
) {
    const promesa = new Promise((resolve) => {
        let direccion = '';
        if (tipo === 0) {
            direccion = 'imagen';
        } else if (tipo === 1) {
            direccion = 'archivos';
        } else if (tipo === 2) {
            direccion = 'audios';
        } else {
            direccion = 'videos';
        }
        // resolve();

        const nombreNuevo = db.push(`multimedia/${datos.id_empresa}/${direccion}`, {
            usuario: user.id,
            usuario_name: datos.nombre
        });

        const extensionArchivo = FileExtension(archivo.name);
        if (extensionArchivo === undefined) {
            resolve();
        }

        const storage = GetStorage();
        const storageRef = RefStorage(
            storage,
            `/empresas/${datos.id_empresa}/bucket/empresa/${direccion}/${nombreNuevo}.${extensionArchivo}`
        );

        const uploadTask = UploadBytesResumable(storageRef, archivo);
        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion
        uploadTask.on(
            'state_changed',
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(progress);
            },
            (error) => {
                // Handle unsuccessful uploads
                setAlerta({ open: true, texto: 'Erro al subir el archivo', tipo: 'error' });
                setSubiendo(false);
                resolve();
            },
            () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                GetDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    db.set(`multimedia/${datos.id_empresa}/${direccion}/${nombreNuevo}`, {
                        nombre: nombreArchivo,
                        link: downloadURL,
                        tipo: FileTipo(tipo),
                        tamano: parseInt(archivo.size / 1024, 10),
                        usuario: user.id,
                        usuario_name: datos.nombre,
                        ref: `/empresas/${datos.id_empresa}/bucket/empresa/${direccion}/${nombreNuevo}.${extensionArchivo}`
                    });
                    setSubiendo(false);
                    setUploadFile(false);
                    setArchivosNuevos([]);
                    LoadMultimedia(dispach, datos);
                    resolve();
                });
            }
        );
    });
}

export function BorrarArchivo(datos, multimedia, LoadMultimedia, setAlerta, dispach, setborrando, resolve) {
    const storage = GetStorage();
    const storageRef = RefStorage(storage, multimedia.ref);
    DeleteObject(storageRef)
        .then(() => {
            const direccion = GetDireccion(multimedia.tipo);
            db.set(`multimedia/${datos.id_empresa}/${direccion}/${multimedia.id}`, null);
            LoadMultimedia(dispach, datos);
            setborrando(false);
            resolve();
        })
        .catch((error) => {
            setAlerta({ open: true, texto: 'Erro al borrar el archivo', tipo: 'error' });
            setborrando(false);
            resolve();
        });
}

export function CargarArchivosTemporal(
    setPaso,
    datos,
    user,
    archivo,
    tipo,
    setProgress,
    setAlerta,
    setSubiendo,
    usuarioActual,
    bandejaActual,
    error,
    enviando,
    dispach,
    setIndexUsuarioActual,
    setUsuarioActual,
    setBandejaActual,
    ModificarLoad,
    ReiniciarVariables
) {
    const promesa = new Promise((resolve) => {
        let direccion = '';
        if (tipo === 0) {
            direccion = 'imagen';
        } else if (tipo === 1) {
            direccion = 'archivos';
        } else if (tipo === 2) {
            direccion = 'audios';
        } else {
            direccion = 'videos';
        }

        const nombreNuevo = db.push(`multimedia/${datos.id_empresa}/temporal/${direccion}`, {
            usuario: user.id,
            usuario_name: datos.nombre
        });

        const extensionArchivo = FileExtension(archivo.name);
        if (extensionArchivo === undefined) {
            resolve();
        }

        const storage = GetStorage();
        const storageRef = RefStorage(
            storage,
            `/empresas/${datos.id_empresa}/bucket/empresa/temporal/${direccion}/${nombreNuevo}.${extensionArchivo}`
        );

        const uploadTask = UploadBytesResumable(storageRef, archivo);
        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion
        uploadTask.on(
            'state_changed',
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(progress);
            },
            (error) => {
                // Handle unsuccessful uploads
                setAlerta({ open: true, texto: 'Erro al subir el archivo', tipo: 'error' });
                setSubiendo(false);
                resolve();
            },
            () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                GetDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    db.set(`multimedia/${datos.id_empresa}/${direccion}/${nombreNuevo}`, {
                        nombre: '',
                        link: downloadURL,
                        tipo: FileTipo(tipo),
                        tamano: parseInt(archivo.size / 1024, 10),
                        usuario: user.id,
                        usuario_name: datos.nombre,
                        enviado: db.timestamp,
                        ref: `/empresas/${datos.id_empresa}/bucket/empresa/temporal/${direccion}/${nombreNuevo}.${extensionArchivo}`
                    });
                    // enviar archivo
                    // setSubiendo(false);
                    setPaso(1);
                    EnviarMensaje(
                        usuarioActual,
                        downloadURL,
                        datos,
                        user,
                        FileTipo(tipo),
                        '',
                        parseInt(archivo.size / 1024, 10),
                        'AD',
                        bandejaActual,
                        error,
                        enviando,
                        dispach,
                        setIndexUsuarioActual,
                        setUsuarioActual,
                        setBandejaActual,
                        ModificarLoad,
                        ReiniciarVariables
                    );
                    resolve();
                });
            }
        );
    });
}
