import {
    Basedatos,
    Push,
    OnValue,
    Setdb,
    Updatedb,
    OnDisconnect,
    Ref,
    Timestamp,
    OrderByChild,
    EqualTo,
    Query,
    LimitToFirst,
    LimitToLast,
    Off,
    OnChildChanged,
    OnChildAdded,
    StartAfter,
    OnChildRemoved
} from './setup';

function writedbOnDisconnect(ref, data) {
    return OnDisconnect(Ref(Basedatos, ref)).set(data);
}

function writedbOnDisconnectUpdate(ref, data) {
    return OnDisconnect(Ref(Basedatos, ref)).update(data);
}

function writedbSet(ref, data) {
    return Setdb(Ref(Basedatos, ref), data);
}

function writedbUpdate(ref, data) {
    return Updatedb(Ref(Basedatos, ref), data);
}

function refDatabase(ref) {
    return Ref(Basedatos, ref);
}

function readOnceDatabase(ref, funcion) {
    return OnValue(Ref(Basedatos, ref), funcion, {
        onlyOnce: true
    });
}

function readOnDatabase(ref, funcion) {
    return OnValue(Ref(Basedatos, ref), funcion, {
        onlyOnce: false
    });
}

function pushDataBase(ref, data) {
    return Push(Ref(Basedatos, ref), data).key;
}

function readOnceQuery(queryy, funcion) {
    return OnValue(queryy, funcion, {
        onlyOnce: true
    });
}

function readOnQuery(queryy, funcion) {
    return OnValue(queryy, funcion, {
        onlyOnce: false
    });
}

export const db = {
    set: writedbSet,
    update: writedbUpdate,
    push: pushDataBase,
    onDisconnect: writedbOnDisconnect,
    onDisconnectUpdate: writedbOnDisconnectUpdate,
    read: {
        on: readOnDatabase,
        once: readOnceDatabase,
        oncequery: readOnceQuery,
        onquery: readOnQuery
    },
    ref: refDatabase,
    timestamp: Timestamp(),
    orderByChild: OrderByChild,
    equalTo: EqualTo,
    query: Query,
    limitToFirst: LimitToFirst,
    limitToLast: LimitToLast,
    off: Off,
    onChildAdded: OnChildAdded,
    startAfter: StartAfter,
    onChildChanged: OnChildChanged,
    onChildRemoved: OnChildRemoved
};

export const otroa = '';
