import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { UseUserState } from 'context/UserContext';

export default function Load() {
    const { mensajeLoad } = UseUserState();

    return (
        <>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={mensajeLoad}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}
