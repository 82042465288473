import React, { useEffect } from 'react';

const ThemeStateContext = React.createContext();
const ThemeDispatchContext = React.createContext();

function userReducer(state, action) {
    switch (action.type) {
        case 'END_LOAD':
            return { ...state, themeReady: false };
        case 'PERSONALIZADO':
            return { ...state, personalizado: action.personalizado };
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

function ThemeProvider({ children }) {
    const [state, dispatch] = React.useReducer(userReducer, {
        personalizado: '',
        themeReady: true
    });

    return (
        <ThemeStateContext.Provider value={state}>
            <ThemeDispatchContext.Provider value={dispatch}>{children}</ThemeDispatchContext.Provider>
        </ThemeStateContext.Provider>
    );
}

function UseThemeState() {
    const context = React.useContext(ThemeStateContext);
    if (context === undefined) {
        throw new Error('useUserState must be used within a UserProvider');
    }
    return context;
}

function UseThemeDispatch() {
    const context = React.useContext(ThemeDispatchContext);
    if (context === undefined) {
        throw new Error('useUserDispatch must be used within a UserProvider');
    }
    return context;
}

// ###########################################################

export { ThemeProvider, UseThemeState, UseThemeDispatch };
