import React, { useEffect } from 'react';
import {
    db,
    auth,
    watchUserChanges,
    watchDatosUSer,
    SignInWithEmailAndPassword,
    SignOut,
    WatchThema,
    WatchPreferencias
} from 'services/firebase';
import { darken, lighten } from '@mui/material/styles';
import {
    CargarUsuarios,
    CargarBot,
    CargarActual,
    ModificarDatos,
    NuevosDatos,
    LogAsesor,
    CambiarBandeja,
    AgregarChatcenter,
    SalirChatcenter,
    ActivarContadores,
    EnviarMensaje,
    Preferencias,
    CargarMultimedia,
    GuardarAtributos,
    CargarEtiquetas,
    NuevaEtiqueta,
    GuatadarEtiquetasUsuario,
    AgregarNotificacion,
    AgregarAllNotifications,
    EliminarNotificacion,
    AceptarTodo,
    MarcarComoVisto,
    MostrarUsuario,
    ContadorInicialBandeja,
    AgregarContadorBandeja
} from './funciones';
import { MLog } from 'services/FuncionesDev';

const UserStateContext = React.createContext();
const UserDispatchContext = React.createContext();

const temaDefault = {
    secondary: {
        light: '#f9babe',
        main: '#353535',
        dark: '#888686'
    },
    primary: {
        light: '#84252b',
        main: '#e03a44',
        dark: '#84252b'
    },
    colordivider: {
        primary: '#2196f330',
        secondary: '#673ab730'
    },
    text: {
        primaryChat: '#bdc8f0',
        secondaryChat: '#8492c4',
        contrastePrimary: 'white',
        contrasteSecondary: 'white'
    },
    warningMain: '#fff4e5',
    imagenes: {
        fondoChat:
            'https://firebasestorage.googleapis.com/v0/b/avidg-fb168.appspot.com/o/temaLogin%2F275072259_493869745599600_3306070473414927555_n.jpg?alt=media&token=cda3c3b6-ac6d-4b21-82a3-b92b351693c8'
    }
};

function userReducer(state, action) {
    switch (action.type) {
        case 'END_LOAD':
            MLog.print('datos context', action.datos);
            return { ...state, user: action.user, datos: action.datos, authReady: true, isAuthenticated: true };
        case 'LOGIN_SUCCESS':
            return { ...state, isAuthenticated: true };
        case 'SIGN_OUT_SUCCESS':
            return { ...state, isAuthenticated: false, user: null, datos: [], authReady: true };
        case 'LOGIN_FAILURE':
            return { ...state, isAuthenticated: false, user: null, datos: [], authReady: true };
        case 'SET_TEMA':
            return { ...state, temaActual: action.tema, temaReady: action.temaReady };
        /* -------------------Bandejas --------------------------- */
        case 'SET_BANDEJAS':
            return { ...state, bandejas: action.bandejas };
        case 'SET_BANDEJAS_ASIGNADAS':
            return { ...state, bandejasAsignadas: action.bandejas };
        case 'SET_BANDEJA_ACTUAL':
            ActivarContadores(state.datos, state.usuarioActual, '');
            return { ...state, bandejaActual: action.bandeja, loadingListUser: true, usuarioActual: '', indexListachat: 0 };
        /* --------------------Lista de usuarios ------------------- */
        case 'LOADING_LISTA_USER':
            return { ...state, loadingListUser: action.loading };
        case 'INIT_LISTA_USER':
            CargarActual(state.listaUsuarios, [], state.bandejaActual, 0, state.limiteChat, null, null, null, state.tableHashUser, state);
            return state;
        /* ---------------------Cargar datos ----------------------- */
        case 'SET_USUARIOS':
            MLog.print('nueva lista user', { listaUsuarios: action.listaUsuarios, tableHashUser: action.mapLista });
            state.usuarioActual = '';
            state.listaUsuariosActual = [];
            CargarActual(action.listaUsuarios, [], state.bandejaActual, 0, state.limiteChat, null, null, null, action.mapLista, state);
            // return { ...state, listaUsuarios: action.listaUsuarios, tableHashUser: action.mapLista, listaUsuariosActual: [] };
            return { ...state, listaUsuarios: action.listaUsuarios, tableHashUser: action.mapLista };
        case 'PREFERENCIAS':
            return { ...state, preferencias: action.preferencias };
        case 'LISTA_BOTRED':
            return { ...state, listaBotRedes: action.listaBotRedes };
        case 'SET_USUARIOS_ACTUAL':
            return { ...state, listaUsuariosActual: action.listaUsuariosActual, loadingListUser: false };
        case 'CARGAR_MAS':
            return { ...state, cargarmas: action.cargarmas };
        /* --------------------Usuario actual-------------------------- */
        case 'USUARIO_ACTUAL':
            ActivarContadores(state.datos, state.usuarioActual, action.usuarioActual);
            return { ...state, usuarioActual: action.usuarioActual };
        case 'INDEX_USUARIO_ACTUAL':
            return { ...state, indexListachat: action.indexListachat };
        /* ---------------------------------------------------------------- */
        case 'MODIFICAR_LISTA_DATOS':
            return {
                ...state,
                usuarioActual: ModificarDatos(
                    action.modificar,
                    state.listaUsuarios,
                    state.listaUsuariosActual,
                    state.bandejaActual,
                    state.tableHashUser,
                    state.usuarioActual
                )
            };
        case 'AGREGAR_LISTA_DATOS':
            NuevosDatos(action.modificar, state.listaUsuarios, state.listaUsuariosActual, state.bandejaActual);
            return { ...state };
        /* ----------------------------Mostrar load--------------------------------------*/
        case 'MOSTRAR_LOAD':
            return { ...state, mensajeLoad: action.mensajeLoad };
        /* --------------------------CARGAR archivos----------------------------------------*/
        case 'CARGAR_ARCHIVOS':
            if (action.tipo === 0) {
                return { ...state, listaImagenes: action.archivos };
            }

            if (action.tipo === 1) {
                return { ...state, listaArchivos: action.archivos };
            }

            if (action.tipo === 2) {
                return { ...state, listaAudios: action.archivos };
            }

            if (action.tipo === 3) {
                return { ...state, listaVideos: action.archivos };
            }

            return { ...state };
        /* -----------------------------------Atributos-----------------------------------------------*/
        case 'OPEN_ATRIBUTOS':
            return { ...state, showAtributos: action.showAtributos };
        case 'USER_ATRIBUTOS':
            return { ...state, usuarioAtributos: action.usuarioAtributos };
        /* -----------------------------------------Mostrar chat------------------------------------------------- */
        case 'MOSTRAR_LISTA':
            return { ...state, mostrarChat: action.mostrarChat };
        /* ----------------------------------------------ETIQUETAS---------------------------------*/
        case 'ETIQUETAS_USER':
            return { ...state, etiquetasUser: action.etiquetasUser, etiquetasUserID: action.etiquetasUserID };
        case 'ETIQUETAS_MAIN':
            return { ...state, etiquetasMain: action.etiquetasMain };
        case 'ETIQUETAS_LISTA':
            return { ...state, listaEtiqetas: action.listaEtiqetas };
        /* ---------------------------------NOTIFICATION----------------------------------------------- */
        case 'AGREGAR_NOTIFICATION':
            AgregarNotificacion(action.notificacion, state);
            return { ...state };
        case 'AGREGAR_ALL_NOTIFICATION':
            AgregarAllNotifications(action.notificacion, state);
            return { ...state };
        case 'ELIMINAR_NOTIFICATION':
            EliminarNotificacion(action.notificacion, state);
            return { ...state };
        case 'ACEPTARTODO_NOTIFICATION':
            AceptarTodo(state);
            return { ...state };
        case 'ACEPTAR_NOTIFICATION':
            MarcarComoVisto(state, action.index);
            return { ...state };
        case 'MOSTAR_NOTIFICATION':
            MostrarUsuario(state, action.index);
            return { ...state };
        case 'ERROR_NOTIFICATION':
            return { ...state, errorNotificacion: action.errorNotificacion };
        case 'SET_NOTIFICATION':
            return { ...state, notificacionesContext: action.arreglo };
        /* -------------------------------CONTADOR DE USUARIOS BANDEJA------------------------------------------------ */
        case 'SET_CONTADOR_BANDEJA':
            ContadorInicialBandeja(state, action.lista, action.ifo, action.bandeja);
            return { ...state };
        case 'ADD_CONTADOR_BANDEJA':
            AgregarContadorBandeja(state, action.item, action.ifo, action.bandeja);
            return { ...state };
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

function UserProvider({ children }) {
    const [state, dispatch] = React.useReducer(userReducer, {
        tableHashUser: new Map(),
        isAuthenticated: false,
        user: null, // son los datos del usuario obtenidos de firebase ath
        datos: [],
        temaReady: false,
        authReady: false,
        temaActual: temaDefault,
        bandejaActual: { title: '', id: -1 },
        bandejas: [], // lista global de bandejas
        loadingListUser: false,
        listaUsuarios: [], // son todos los usuarios obtenidos dede la base de datos
        limiteChat: 15,
        usuarioActual: '',
        preferencias: false,
        listaBotRedes: [],
        indexListachat: 0, // es el index del usuario actual
        listaUsuariosActual: [], // son los usuarios que se muestran en la lista de usuarios
        limiteListaActual: 0, // es el tamanio total de la lista que se muestra actualmente
        cargarmas: { ultimo: 0, cargarmas: false },
        mensajeLoad: false,
        listaArchivos: [],
        listaImagenes: [],
        listaAudios: [],
        listaVideos: [],
        showAtributos: false,
        usuarioAtributos: '',
        mostrarChat: 1, // en la version movil se usa esta variable para mostrar la lista de usuarios o la conversaciones 1: mostrar lista usuarios 0 : mostrar chat
        etiquetasUser: false, // muestra el administrador de etiquetas para cada usuario
        etiquetasMain: false, // muestra el administrador de etiquetas global sin asignarla a ningun usuairo
        etiquetasUserID: '', // el id del usuario de la redes sociales que se quiere adminsitrar las etiquetas {id:kkk, idbot:1, red:facebook}
        listaEtiqetas: {},
        bandejasAsignadas: [], // lista d ebandejas asignadas al usuairo actual
        notificacionesContext: [],
        errorNotificacion: { open: false, mensaje: '', titulo: '' },
        hashBandejas: new Map() // cantidad de usuarios en cada bandeja
    });

    useEffect(() => {
        WatchThema('123456', (tema) => {
            if (tema != null) {
                const actual = {
                    secondary: {
                        light: lighten(tema.secondary, 0.5),
                        main: tema.secondary,
                        dark: darken(tema.secondary, 0.15)
                    },
                    primary: {
                        light: lighten(tema.primary, 0.5),
                        main: tema.primary,
                        dark: darken(tema.primary, 0.15)
                    },
                    colordivider: {
                        primary: '#2196f330',
                        secondary: '#673ab730'
                    },
                    text: {
                        primaryChat: tema.text.primaryChat,
                        secondaryChat: tema.text.secondaryChat,
                        contrastePrimary: tema.text.contrastePrimary,
                        contrasteSecondary: tema.text.contrasteSecondary
                    },
                    imagenes: {
                        fondoChat: tema.imagenes.fondoChat
                    },
                    warningMain: '#fff4e5'
                };
                dispatch({ type: 'SET_TEMA', tema: actual, temaReady: true });
            } else {
                dispatch({ type: 'SET_TEMA', tema: temaDefault, temaReady: true });
            }
        });
        watchUserChanges((user) => {
            if (user) {
                watchDatosUSer(user.id, (datos) => {
                    MLog.print('datos del user cambiaron');
                    if (datos.en_linea) {
                        dispatch({ type: 'END_LOAD', user, datos });
                    }
                });
                WatchPreferencias(user.id, (datos) => {
                    if (datos.en_linea) {
                        dispatch({ type: 'END_LOAD', user, datos });
                    } else {
                        dispatch({ type: 'LOADING_LISTA_USER', loading: true });
                        CargarUsuarios(datos, dispatch, user.id);
                        CargarBot(datos, dispatch);
                        MLog.print('cargar etiquetas context');
                        dispatch({ type: 'END_LOAD', user, datos });
                    }
                });
                CargarEtiquetas(user.id, dispatch);
            } else {
                dispatch({ type: 'LOGIN_FAILURE' });
            }
        });
    }, []);

    return (
        <UserStateContext.Provider value={state}>
            <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
        </UserStateContext.Provider>
    );
}

function UseUserState() {
    const context = React.useContext(UserStateContext);
    if (context === undefined) {
        throw new Error('useUserState must be used within a UserProvider');
    }
    return context;
}

function UseUserDispatch() {
    const context = React.useContext(UserDispatchContext);
    if (context === undefined) {
        throw new Error('useUserDispatch must be used within a UserProvider');
    }
    return context;
}

// ###########################################################
function LoginUser(dispatch, correo, password, setError, setErrordetail) {
    MLog.print('login');
    if (!!correo && password) {
        SignInWithEmailAndPassword(auth, correo, password)
            .then((userCredential) => {
                const user = userCredential.user;
                MLog.print('user', user);
                if (user.emailVerified) {
                    MLog.print('Email verificado');
                } else {
                    MLog.print('Email no verificado');
                }
            })
            .catch((error) => {
                // setErrordetail(error.message);
                // setErrordetail("error")
                // auth/user-not-found error.code "el usuario no existe"
                // auth/wrong-password password incorrecto
                // auth/too-many-requests se supero el limite de intentso
                if (error.code === 'auth/user-not-found') {
                    setErrordetail({ submit: 'El usuario ingresado no es valido' });
                } else if (error.code === 'auth/wrong-password') {
                    setErrordetail({ submit: 'credenciales incorrectas' });
                } else if (error.code === 'auth/too-many-requests') {
                    setErrordetail({ submit: 'Se supero el limite de intentos, por favor intente mas tarde' });
                } else {
                    setErrordetail({ submit: 'Ocurrio un error, por favor intente de nuevo' });
                }
                setError({ success: false });

                // console.error('Error signing in with password and email', error.message);
                // console.error('Error codigo error', error.code);
            });
    } else {
        MLog.print('datos incompletos');
    }
}

function Logaout(dispatch, history) {
    MLog.print('logOut', auth);
    SignOut(auth)
        .then(() => {
            dispatch({ type: 'SIGN_OUT_SUCCESS' });
            MLog.print('singOut correcto');
        })
        .catch((error) => {
            MLog.print('error logOut');
        });
}

function UserForgetPassword(login, history, setIsLoading, setError, setErrordetail, setSuccsesForget) {
    MLog.print('userForgetPassword');
}

function UserNewPassword(password, setIsLoading, setError, setErrordetail, setSuccsesNewPassword) {
    MLog.print('New password');
}

/* ------------------------------- Bandejas ------------------------------------------------ */
function SetBandejas(dispatch, bandejas) {
    dispatch({ type: 'SET_BANDEJAS', bandejas });
}

function SetBandejasAsignadas(dispatch, bandejas) {
    dispatch({ type: 'SET_BANDEJAS_ASIGNADAS', bandejas });
}

function SetBandejaActual(dispatch, bandeja) {
    dispatch({ type: 'SET_BANDEJA_ACTUAL', bandeja });
    // dispatch({ type: 'LOADING_LISTA_USER', loading: true });
}

/* ---------------------------------LISTA USUARIOS------------------------------------------- */
function SetLoadingLisaUser(dispatch, loading) {
    dispatch({ type: 'LOADING_LISTA_USER', loading });
}

function SetListaActual(dispatch, listaUsuariosActual) {
    dispatch({ type: 'SET_USUARIOS_ACTUAL', listaUsuariosActual });
}

function SetCargarMas(dispatch, cargarmas) {
    dispatch({ type: 'CARGAR_MAS', cargarmas });
}
/* -------------------------------------USUARIO ACTUAL---------------------------------------- */
function UsuarioActual(dispatch, usuarioActual) {
    MLog.print('cambio usuario actual', usuarioActual);
    dispatch({ type: 'USUARIO_ACTUAL', usuarioActual });
}

function IndexUsuarioActual(dispatch, indexListachat) {
    dispatch({ type: 'INDEX_USUARIO_ACTUAL', indexListachat });
}
/* ---------------------------------------PREFERENCIAS-------------------------------------------- */
function MostrarPreferencias(dispatch, preferencias) {
    dispatch({ type: 'PREFERENCIAS', preferencias });
}

/* ---------------------------------------Estados lista---------------------------------------- */
function ModificarLista(dispatch, nuevo) {
    dispatch({ type: 'MODIFICAR_LISTA_DATOS', modificar: nuevo });
}
/* ------------------------------------------MOSTRAR LOAD------------------------------------------ */
function ModificarLoad(dispatch, modificar) {
    dispatch({ type: 'MOSTRAR_LOAD', mensajeLoad: modificar });
}
/*-------------------------------------------------------------------------------------------------*/
function EnviarAbandeja(bandejaDestino, usuarioActual, datos, user, notificacion) {
    CambiarBandeja(bandejaDestino, usuarioActual, datos, user, notificacion);
}

function EnviarChatcenter(user, datos, usuarioActual, dispach, setIndexUsuarioActual, setUsuarioActual, setBandejaActual, ModificarLoad) {
    AgregarChatcenter(user, datos, usuarioActual, dispach, setIndexUsuarioActual, setUsuarioActual, setBandejaActual, ModificarLoad);
}

function TerminarAtencion(user, datos, usuarioActual, tipo, usuarioActualContext, SetUsuarioActual, SetIndexUsuarioActual, dispach) {
    SalirChatcenter(user, datos, usuarioActual, tipo, usuarioActualContext, SetUsuarioActual, SetIndexUsuarioActual, dispach);
}
function SendMensaje(
    usuarioActual,
    mensajeEnviar,
    datos,
    user,
    tipo,
    nombre,
    tamanio,
    propietario,
    bandeja,
    error,
    enviando,
    dispach,
    setIndexUsuarioActual,
    setUsuarioActual,
    setBandejaActual,
    ModLoad,
    ReiniciarVariables
) {
    MLog.print('context enviar contexxto');
    EnviarMensaje(
        usuarioActual,
        mensajeEnviar,
        datos,
        user,
        tipo,
        nombre,
        tamanio,
        propietario,
        bandeja,
        error,
        enviando,
        dispach,
        setIndexUsuarioActual,
        setUsuarioActual,
        setBandejaActual,
        ModificarLoad,
        ReiniciarVariables
    );
}

function CambiarPreferencias(dispatch, red, bot, user, datos, MostrarPreferencias) {
    Preferencias(dispatch, red, bot, user, datos, MostrarPreferencias);
}

function LoadMultimedia(dispatch, datos) {
    CargarMultimedia(dispatch, datos);
}

/* ---------------------------------------------Atributos------------------------------------------------*/
function OpenAtributos(dispatch, dato) {
    dispatch({ type: 'OPEN_ATRIBUTOS', showAtributos: dato });
}

function UserAtributos(dispatch, dato) {
    dispatch({ type: 'USER_ATRIBUTOS', usuarioAtributos: dato });
}

function ContextGuardarAtributo(nombre, valor, datos, usuarioActual, ReiniciarVariables) {
    GuardarAtributos(nombre, valor, datos, usuarioActual, ReiniciarVariables);
}

/* ---------------------------------------------Mostrar lista ---------------------------------------------------*/
function MostrarLista(dispatch, mostrarChat) {
    dispatch({ type: 'MOSTRAR_LISTA', mostrarChat });
}

/* ---------------------------------------------ETIQUETAS ---------------------------------------------------*/
function EtiquetasUSer(dispatch, etiquetasUser, etiquetasUserID) {
    dispatch({ type: 'ETIQUETAS_USER', etiquetasUser, etiquetasUserID });
}

function EtiquetasMain(dispatch, etiquetasMain) {
    dispatch({ type: 'ETIQUETAS_MAIN', etiquetasMain });
}

function ListaEtiqetas(dispatch, listaEtiqetas) {
    dispatch({ type: 'ETIQUETAS_LISTA', listaEtiqetas });
}

function NuevaEtiquetaContext(datos, etiqueta, toggleOpen, setCargando, valor, setListaValor) {
    NuevaEtiqueta(datos, etiqueta, toggleOpen, setCargando, valor, setListaValor);
}

function GuatadarEtiquetasUsuarioContext(datos, arregloEtiquetas, usuarioActual, toggleOpen, setCargando) {
    GuatadarEtiquetasUsuario(datos, arregloEtiquetas, usuarioActual, toggleOpen, setCargando);
}

/* -------------------------------------Notificacion------------------------------------------------------- */
function AddAllNotificacion(dispatch, notificacion) {
    dispatch({ type: 'AGREGAR_ALL_NOTIFICATION', notificacion });
}
function AddNotification(dispatch, notificacion) {
    dispatch({ type: 'AGREGAR_NOTIFICATION', notificacion });
}

function DeleteNotification(dispatch, notificacion) {
    dispatch({ type: 'ELIMINAR_NOTIFICATION', notificacion });
}

function AcceptAll(dispatch) {
    dispatch({ type: 'ACEPTARTODO_NOTIFICATION' });
}

function AcceptNotificacion(dispatch, index) {
    dispatch({ type: 'ACEPTAR_NOTIFICATION', index });
}

function ShowNotification(dispatch, index) {
    dispatch({ type: 'MOSTAR_NOTIFICATION', index });
}

function ErrorNotification(dispatch, index) {
    dispatch({ type: 'ERROR_NOTIFICATION', index });
}

function SetNotification(dispatch, arreglo) {
    dispatch({ type: 'SET_NOTIFICATION', arreglo });
}

/* --------------------------------------CONTADOR BANDEJAS---------------------------------------------------*/
function SetContadorBandeja(dispatch, lista, ifo, bandeja) {
    dispatch({ type: 'SET_CONTADOR_BANDEJA', lista, ifo, bandeja });
}

function AddContadorBandeja(dispatch, item, ifo, bandeja) {
    dispatch({ type: 'ADD_CONTADOR_BANDEJA', item, ifo, bandeja });
}

export { UserProvider, UseUserState, UseUserDispatch, LoginUser, Logaout, UserForgetPassword, UserNewPassword };
export { SetBandejas, SetBandejaActual, SetBandejasAsignadas };
export { SetLoadingLisaUser, SetListaActual, SetCargarMas };
export { UsuarioActual, IndexUsuarioActual };
export { MostrarPreferencias };
export { ModificarLista };
export { ModificarLoad };
/*---------------------------------------------------*/
export { EnviarAbandeja, EnviarChatcenter, TerminarAtencion, SendMensaje, CambiarPreferencias };

export { LoadMultimedia };
export { OpenAtributos, UserAtributos };
export { ContextGuardarAtributo };
export { MostrarLista };
export { EtiquetasUSer, EtiquetasMain, NuevaEtiquetaContext, GuatadarEtiquetasUsuarioContext };
export {
    AddAllNotificacion,
    AddNotification,
    DeleteNotification,
    AcceptAll,
    AcceptNotificacion,
    ShowNotification,
    ErrorNotification,
    SetNotification
};
export { SetContadorBandeja, AddContadorBandeja };
