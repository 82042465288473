import React, { useEffect } from 'react';
import { db } from 'services/firebase';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import Typography from '@mui/material/Typography';
import ChatIcon from '@mui/icons-material/Chat';
import GroupIcon from '@mui/icons-material/Group';
import Badge from '@mui/material/Badge';
import { MLog } from 'services/FuncionesDev';

// user context
import { UseUserState, SetBandejas, UseUserDispatch, SetBandejaActual, SetBandejasAsignadas } from 'context/UserContext';

export default function MenuLateral() {
    const { datos, bandejas, bandejaActual, user, bandejasAsignadas, hashBandejas } = UseUserState();
    const userDispatch = UseUserDispatch();

    useEffect(() => {
        /* Obtener bandejas actuales */
        db.read.once(`bandeja_cc/${datos.id_empresa}`, (snap) => {
            if (snap.val() === null) {
                // no hay Bandejas
                SetBandejas(userDispatch, []);
            } else {
                SetBandejas(userDispatch, snap.val());
            }
        });

        db.read.once(`asignar_asesor/${datos.id_empresa}/${user.id}/bandejas`, (snap) => {
            MLog.print('snap.val()', snap.val());
            if (snap.val() === null) {
                // no hay Bandejas
                SetBandejasAsignadas(userDispatch, []);
            } else {
                SetBandejasAsignadas(userDispatch, snap.val());
            }
        });
        if (datos.permisos.bandejas.inicio !== undefined) {
            SetBandejaActual(userDispatch, { title: datos.permisos.bandejas.inicio, id: datos.permisos.bandejas.inicioid });
        }
    }, []);

    const ConteoUsuarios = (item) => {
        const conteoBandeja = hashBandejas.get(item);
        if (conteoBandeja !== undefined) {
            return conteoBandeja.size;
        }
        return 0;
    };

    const Permisos = (item) => {
        if (datos.permisos.bandejas.admin) return true;

        if (bandejasAsignadas.indexOf(item) > -1) return true;

        return false;
    };

    return (
        <Box sx={{ height: '100%', width: '100%', bgcolor: 'background.paper' }}>
            <nav aria-label="main mailbox folders">
                <List>
                    {Permisos('chatcenter') && (
                        <ListItem disablePadding>
                            <ListItemButton
                                selected={bandejaActual.id === 0}
                                onClick={(e) => SetBandejaActual(userDispatch, { title: 'chatcenter', id: 0 })}
                            >
                                <ListItemIcon>
                                    <ChatIcon />
                                </ListItemIcon>
                                <ListItemText primary="Chatcenter" />
                            </ListItemButton>
                        </ListItem>
                    )}
                    {Permisos('usuarios') > -1 && (
                        <ListItem disablePadding>
                            <ListItemButton
                                selected={bandejaActual.id === 1}
                                onClick={(e) => SetBandejaActual(userDispatch, { title: 'usuarios', id: 1 })}
                            >
                                <ListItemIcon>
                                    <GroupIcon />
                                </ListItemIcon>
                                <ListItemText primary="Usuarios" />
                            </ListItemButton>
                        </ListItem>
                    )}
                    <Divider />
                    {bandejasAsignadas.map(
                        (item, index) =>
                            item !== 'chatcenter' && (
                                <ListItem disablePadding key={`badeja_${item}`}>
                                    <ListItemButton
                                        selected={bandejaActual.id === index + 2}
                                        onClick={(e) => SetBandejaActual(userDispatch, { title: item, id: index + 2 })}
                                    >
                                        <ListItemText primary={item} />
                                        <Badge badgeContent={ConteoUsuarios(item)} color="primary" />
                                    </ListItemButton>
                                </ListItem>
                            )
                    )}
                </List>
            </nav>
        </Box>
    );
}
