// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const LogoAD = ({ tipo }) => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={logo} alt="Berry" width="100" />
         *
         */
        <svg
            width="180"
            height="32"
            viewBox="0 0 3127 448"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            enableBackground="new 0 0 3127 448"
        >
            <path
                fill={tipo === 0 ? '#FFFFFF' : theme.palette.primary.main}
                opacity="1.000000"
                stroke="none"
                d={`M0.999999,337.000000
            	C1.000000,225.021896 1.000000,113.043808 1.000000,1.000000
            	C591.362732,1.000000 1181.725464,1.000000 1772.845459,1.364906
            	C1770.019653,2.486550 1766.424561,3.190818 1762.855835,4.010324
            	C1753.658691,6.122359 1744.473145,8.285346 1734.914307,10.513942
            	C1738.852661,23.416130 1742.568359,35.588959 1746.453857,48.318367
            	C1748.217773,47.890450 1749.878540,47.403625 1751.571411,47.090229
            	C1764.441895,44.707771 1777.234375,41.314342 1790.214966,40.269653
            	C1805.770020,39.017750 1821.420288,39.995728 1836.938965,42.899109
            	C1861.313843,47.459423 1883.696167,56.706665 1904.471802,70.018860
            	C1920.499390,80.288803 1934.073120,93.327202 1946.328491,107.812927
            	C1958.302002,121.965385 1968.063599,137.613297 1974.776733,154.770981
            	C1984.708862,180.156281 1990.586060,206.497269 1987.951172,234.062088
            	C1986.914429,244.906921 1986.201416,255.894608 1983.832520,266.479614
            	C1976.134644,300.875031 1959.190430,330.453217 1934.692505,355.627747
            	C1928.327759,362.168304 1921.185059,368.073761 1913.837646,373.511719
            	C1896.800659,386.121429 1878.217285,395.916687 1857.802124,401.928406
            	C1855.146484,402.710449 1855.045044,403.351227 1855.863037,405.926758
            	C1859.161621,416.313507 1862.005127,426.844238 1865.071899,437.305603
            	C1865.988159,440.430511 1868.284790,440.562622 1870.867188,439.609589
            	C1874.919312,438.114075 1878.924927,436.490753 1882.990112,435.032623
            	C1912.237671,424.542206 1937.482300,407.646545 1959.911255,386.441223
            	C1966.221924,380.474762 1972.155396,373.939789 1977.316406,366.965881
            	C1985.360596,356.096100 1993.113403,344.937225 2000.048340,333.338074
            	C2009.146851,318.120148 2015.249390,301.537048 2020.058472,284.451202
            	C2024.751587,267.777313 2027.285034,250.747437 2027.802368,233.547440
            	C2028.162231,221.578171 2027.210083,209.490707 2025.840454,197.571381
            	C2024.590088,186.690872 2022.616333,175.802032 2019.851929,165.206360
            	C2014.389038,144.268356 2005.548828,124.712624 1994.077148,106.305046
            	C1982.425171,87.608200 1968.448853,70.942459 1951.893799,56.449734
            	C1947.250610,52.384995 1942.312744,48.650681 1937.437622,44.858013
            	C1923.502441,34.016888 1908.267700,25.375937 1892.022827,18.539585
            	C1874.167603,11.025572 1855.751221,5.437521 1836.469727,2.928666
            	C1835.578003,2.812621 1834.820190,1.666397 1834.000000,0.999988
            	C2265.222900,1.000000 2696.446045,1.000000 3128.000000,1.000000
            	C3128.000000,106.029137 3128.000000,211.058624 3127.531250,316.544067
            	C3099.810547,317.000000 3072.558594,317.000000 3044.955078,317.000000
            	C3044.955078,265.475922 3044.955078,214.434341 3044.955078,163.197296
            	C3033.869141,163.197296 3023.137207,163.197296 3012.319824,163.197296
            	C3012.319824,223.874741 3012.319824,284.360626 3012.319824,345.000000
            	C3051.093018,345.000000 3089.546387,345.000000 3128.000000,345.000000
            	C3128.000000,379.656494 3128.000000,414.313019 3128.000000,449.000000
            	C2694.637207,449.000000 2261.274658,449.000000 1827.452148,448.599518
            	C1823.654419,437.162079 1820.131104,426.176025 1817.074463,415.061646
            	C1815.947144,410.962006 1814.035156,409.728821 1809.869019,409.910858
            	C1793.261841,410.636505 1776.840942,409.275330 1760.612671,405.315155
            	C1743.178589,401.060760 1726.743286,394.521606 1711.156128,385.658051
            	C1689.099609,373.115753 1670.560547,356.513397 1655.261597,336.448975
            	C1645.115112,323.142090 1637.053345,308.507538 1630.930298,292.821472
            	C1623.271973,273.202484 1618.529419,252.908279 1618.219971,231.920898
            	C1618.030029,219.028854 1619.550781,206.050491 1621.114746,193.209686
            	C1622.930542,178.301437 1627.811768,164.179169 1633.895142,150.447891
            	C1640.798218,134.866669 1649.380005,120.227303 1660.466187,107.394409
            	C1668.693970,97.870346 1678.152954,89.330612 1687.620605,80.984253
            	C1694.239380,75.149323 1701.798828,70.381424 1708.903442,65.162460
            	C1704.986084,52.467934 1701.062744,39.753963 1696.623535,25.367861
            	C1682.475098,35.837524 1669.254272,45.375675 1656.305908,55.270454
            	C1642.993896,65.443161 1632.192993,78.197823 1622.344971,91.536118
            	C1610.782349,107.196388 1601.187744,124.149521 1594.167236,142.436447
            	C1585.898071,163.976089 1580.455811,186.226089 1579.185181,209.236740
            	C1578.484375,221.926636 1579.102783,234.767853 1580.169067,247.453201
            	C1581.102783,258.561768 1582.619507,269.775909 1585.428223,280.539001
            	C1590.704590,300.758209 1598.405396,320.118896 1608.981201,338.286774
            	C1622.638062,361.747528 1640.102905,382.015625 1660.923462,399.200165
            	C1678.361572,413.593048 1697.769531,425.079590 1718.895142,433.406799
            	C1735.407104,439.915405 1752.301636,445.055511 1770.031372,447.076996
            	C1770.751343,447.159058 1771.346558,448.334167 1772.000000,449.000000
            	C1181.666626,449.000000 591.333313,449.000000 1.000000,449.000000
            	C1.000000,414.563416 1.000000,380.237671 1.468657,345.455933
            	C15.423652,345.000000 28.909990,345.000000 42.323494,345.000000
            	C50.025333,327.236328 57.653816,309.641846 65.302811,292.000061
            	C100.356697,292.000061 135.170319,292.056824 169.983154,291.915985
            	C173.634476,291.901215 174.194199,294.240112 175.191391,296.592041
            	C181.568542,311.632568 187.885117,326.699249 194.389557,341.684479
            	C194.983078,343.051819 196.742340,344.833160 197.988373,344.855530
            	C210.928879,345.087769 223.875137,344.998444 237.049026,344.998444
            	C236.883209,343.741425 236.962097,342.858917 236.643906,342.156189
            	C233.472305,335.151215 230.202301,328.190796 227.022720,321.189362
            	C217.408997,300.019897 207.807434,278.844910 198.230591,257.658752
            	C187.137375,233.118011 176.071732,208.564819 165.001007,184.013916
            	C155.841080,163.700485 146.748901,143.355927 137.415451,123.122597
            	C136.746689,121.672844 134.402359,120.265800 132.739304,120.140877
            	C127.603668,119.755081 122.320480,120.648277 117.276337,119.868752
            	C105.800827,118.095322 98.033623,121.301651 95.771416,133.589783
            	C95.717316,133.883682 95.337532,134.102585 95.202354,134.398758
            	C88.211136,149.716843 81.183723,165.018646 74.258621,180.366623
            	C63.908173,203.306107 53.668560,226.295609 43.306732,249.229935
            	C37.626514,261.802216 31.716879,274.270935 26.046423,286.847565
            	C18.867239,302.770416 11.870649,318.775726 4.628610,334.669556
            	C4.127542,335.769165 2.241805,336.237885 0.999999,337.000000
            M1771.550293,272.036011
            	C1763.367188,244.521835 1755.162231,217.014114 1747.007568,189.491516
            	C1740.309570,166.885529 1733.703003,144.252365 1726.930786,121.668770
            	C1726.513672,120.277779 1725.015869,118.175438 1723.991455,118.158264
            	C1710.060791,117.924637 1696.124756,118.001160 1682.034424,118.001160
            	C1695.051636,162.569824 1707.928223,206.656982 1720.953613,251.253571
            	C1700.271118,251.253571 1680.211426,251.253571 1659.935913,251.253571
            	C1664.025391,265.021027 1668.180664,278.335022 1671.835693,291.784973
            	C1672.763550,295.199432 1674.122314,296.086212 1677.401611,296.061676
            	C1694.900024,295.930725 1712.400269,296.049255 1729.899048,295.945496
            	C1732.448120,295.930389 1734.211060,296.229919 1735.010132,299.037628
            	C1738.610718,311.691711 1742.491333,324.269012 1745.884155,336.977295
            	C1746.736084,340.169067 1747.926270,341.074738 1751.107178,341.066223
            	C1789.438965,340.963867 1827.772949,341.179871 1866.101562,340.820892
            	C1872.402710,340.761871 1878.882690,338.930542 1884.931396,336.886597
            	C1900.076782,331.768738 1911.688843,321.648010 1921.297852,309.166107
            	C1936.750366,289.093506 1944.191406,265.884430 1945.751343,240.995712
            	C1946.518555,228.756760 1944.922852,216.333252 1943.933716,204.030441
            	C1942.834839,190.363480 1937.795410,177.681107 1931.693848,165.692551
            	C1925.742065,153.998535 1917.963867,143.408752 1907.537476,134.881104
            	C1895.120483,124.725471 1881.510742,118.071892 1865.236328,118.024933
            	C1835.070923,117.937889 1804.905029,118.000000 1774.739380,118.000000
            	C1772.928223,118.000000 1771.116943,118.000000 1768.437744,118.000000
            	C1772.876709,133.354614 1777.161621,148.176270 1781.447144,163.000000
            	C1788.793945,163.000000 1795.790649,162.892563 1802.783081,163.022873
            	C1816.363159,163.275940 1829.994507,163.020889 1843.506592,164.150314
            	C1861.894409,165.687317 1876.541748,174.803543 1887.757202,189.308014
            	C1900.629517,205.955307 1903.955811,224.843292 1899.381592,245.040024
            	C1896.121704,259.433105 1888.119019,271.332367 1876.753296,280.594604
            	C1866.996582,288.545502 1855.615601,294.272461 1842.999390,294.776917
            	C1822.366333,295.601868 1801.676270,295.028809 1781.010742,294.921631
            	C1779.879150,294.915771 1778.015991,294.005646 1777.742065,293.127014
            	C1775.642578,286.393616 1773.852905,279.563599 1771.550293,272.036011
            M2324.997559,197.616226
            	C2324.665771,196.541931 2324.287109,195.479630 2324.008789,194.391586
            	C2318.222412,171.774429 2306.183838,153.394028 2286.913818,140.008713
            	C2267.004395,126.179222 2244.503174,120.428261 2220.630127,120.095207
            	C2191.818604,119.693253 2162.997070,120.000000 2134.179932,120.000000
            	C2132.565430,120.000000 2130.951172,120.000000 2129.361084,120.000000
            	C2129.361084,195.276138 2129.361084,270.102722 2129.361084,344.000122
            	C2162.788086,344.000122 2195.853516,344.205444 2228.914062,343.909760
            	C2244.435303,343.770935 2259.298584,339.557831 2273.163086,332.860779
            	C2297.222412,321.239197 2313.546875,302.434540 2322.012207,276.996552
            	C2328.879395,256.361176 2330.069580,235.149109 2327.922607,213.684464
            	C2327.411133,208.570068 2326.000000,203.545639 2324.997559,197.616226
            M909.502380,224.998901
            	C898.456177,250.689606 887.409912,276.380310 876.073975,302.744873
            	C875.155884,301.067719 874.496521,300.043060 874.007690,298.942719
            	C867.757935,284.876373 861.496277,270.815186 855.311951,256.720062
            	C849.836487,244.240585 844.398560,231.743988 839.043579,219.212433
            	C831.300964,201.093292 823.662476,182.929688 815.907593,164.815857
            	C815.582336,164.056259 814.536804,163.102005 813.814880,163.091949
            	C805.233521,162.972214 796.649780,163.014771 788.357666,163.014771
            	C788.357666,224.113495 788.357666,284.518890 788.357666,344.638031
            	C798.460144,344.638031 808.203247,344.638031 817.500000,344.638031
            	C817.500000,309.214020 817.500000,274.151978 817.500000,239.089951
            	C817.841492,239.003860 818.182983,238.917786 818.524475,238.831696
            	C833.807068,274.185455 849.089722,309.539185 864.317017,344.764954
            	C871.953796,344.764954 879.382874,344.764954 886.785339,344.764954
            	C901.694031,309.613831 916.515198,274.669098 931.336365,239.724396
            	C931.871826,239.876282 932.407349,240.028168 932.942810,240.180069
            	C932.942810,275.019897 932.942810,309.859711 932.942810,344.751709
            	C943.228516,344.751709 952.967407,344.751709 962.747925,344.751709
            	C962.747925,284.098297 962.747925,223.693542 962.747925,163.000015
            	C954.863586,163.000015 947.225098,163.115891 939.593689,162.934372
            	C937.074768,162.874451 935.919312,163.733765 934.956238,166.005249
            	C926.697144,185.485519 918.312561,204.912567 909.502380,224.998901
            M617.504211,177.996002
            	C612.074768,183.125931 605.782959,187.612839 601.384277,193.511414
            	C590.086731,208.661316 585.190247,226.392303 584.104492,245.025635
            	C583.053162,263.069153 584.806702,280.906311 592.052185,297.740204
            	C598.731262,313.258118 609.040527,325.782715 624.034912,333.974243
            	C642.186707,343.890686 661.560486,346.581451 682.027527,344.131409
            	C694.386169,342.651978 705.975647,339.656281 716.622864,333.068909
            	C727.818726,326.141998 736.604736,317.080505 743.098694,305.589447
            	C752.694580,288.609558 755.314758,270.293274 755.106201,251.114456
            	C754.904785,232.587326 751.297729,215.164810 741.651062,199.171204
            	C733.027283,184.873505 720.753601,174.942413 705.277893,169.036148
            	C689.095764,162.860291 672.382263,161.855240 655.218689,163.876770
            	C641.623108,165.478058 629.267456,169.779282 617.504211,177.996002
            M2568.025391,207.451874
            	C2571.456299,200.380280 2575.007812,193.362305 2578.193115,186.181763
            	C2578.695068,185.050156 2578.054443,182.524918 2577.071777,181.754272
            	C2561.835938,169.806610 2544.092041,164.158310 2525.090332,163.216675
            	C2514.323486,162.683121 2503.347412,163.632645 2492.646729,165.173019
            	C2478.557861,167.201126 2465.806641,173.226929 2455.043457,182.568497
            	C2436.694336,198.494003 2428.782715,219.713287 2427.244385,243.217209
            	C2426.497803,254.617905 2427.540771,266.302979 2429.190918,277.653259
            	C2431.531250,293.751526 2438.228516,308.291473 2449.681641,320.077057
            	C2466.295166,337.172821 2487.516357,344.111389 2510.635254,344.754425
            	C2523.677246,345.117188 2536.896729,343.721832 2549.832764,341.768707
            	C2559.744141,340.272156 2569.342529,336.683777 2579.067383,333.978973
            	C2582.185059,333.111816 2583.132324,331.102020 2583.092041,327.765137
            	C2582.890869,311.101898 2583.000000,294.434967 2583.000000,277.769318
            	C2583.000000,267.313812 2583.000000,256.858276 2583.000000,246.203766
            	C2559.876465,246.203766 2537.138916,246.203766 2514.200684,246.203766
            	C2514.200684,254.130234 2514.200684,261.868225 2514.200684,270.006836
            	C2526.801758,270.006836 2539.212158,270.006836 2551.999268,270.006836
            	C2551.999268,284.192108 2552.073975,297.981537 2551.856934,311.766327
            	C2551.839600,312.860962 2549.961914,314.596008 2548.680664,314.898376
            	C2534.637207,318.212891 2520.466064,320.483765 2506.009521,317.973938
            	C2496.195312,316.270020 2487.230957,312.793121 2479.497803,306.100006
            	C2466.273926,294.654053 2462.556152,279.165131 2461.177246,262.995575
            	C2460.175537,251.249832 2460.665039,239.383453 2463.961426,227.810272
            	C2468.177979,213.006744 2475.767334,200.595795 2490.317627,194.464005
            	C2505.828613,187.927200 2522.062744,188.185593 2538.220459,192.660629
            	C2549.057861,195.662231 2558.284180,201.763458 2568.025391,207.451874
            M2954.964600,332.638031
            	C2956.307861,335.893494 2957.484375,339.234222 2959.082275,342.359558
            	C2959.666504,343.502563 2961.236328,344.863251 2962.380615,344.885437
            	C2972.515137,345.081757 2982.655029,344.999451 2993.673340,344.999451
            	C2966.363525,283.935913 2939.322754,223.474121 2912.276367,163.000000
            	C2904.472900,163.000000 2896.806885,163.193756 2889.158447,162.910812
            	C2885.794922,162.786362 2884.228516,164.020157 2882.940430,166.974197
            	C2878.262939,177.700394 2873.277100,188.291473 2868.512695,198.980209
            	C2856.647705,225.597321 2844.863525,252.250183 2833.002197,278.868866
            	C2825.303223,296.146820 2817.521973,313.388062 2809.786865,330.649811
            	C2807.726807,335.246948 2805.700928,339.859436 2803.422363,344.999756
            	C2814.643311,344.999756 2824.959229,345.061554 2835.271973,344.909241
            	C2836.367432,344.893097 2838.023682,343.871826 2838.442383,342.897003
            	C2843.744629,330.550262 2848.933594,318.153229 2853.987305,305.702515
            	C2855.163574,302.804810 2856.873779,301.914581 2859.952881,301.931610
            	C2882.771973,302.057831 2905.592285,302.000000 2928.412109,302.000000
            	C2932.866943,302.000000 2937.321533,302.000000 2941.818359,302.000000
            	C2946.130371,312.202301 2950.313721,322.099915 2954.964600,332.638031
            M1012.959961,274.448792
            	C1002.500671,297.771851 992.041382,321.094940 981.321228,344.999817
            	C992.589783,344.999817 1002.905518,345.116455 1013.211548,344.852264
            	C1014.499695,344.819214 1016.300659,343.018372 1016.908264,341.621063
            	C1022.143066,329.582703 1027.210083,317.470123 1032.199463,305.327209
            	C1033.325562,302.586853 1035.055786,301.886017 1037.892700,301.933441
            	C1048.049438,302.103302 1058.210693,302.000000 1068.370239,302.000000
            	C1085.496460,302.000000 1102.622559,302.000000 1119.668213,302.000000
            	C1125.524292,315.540558 1131.215942,328.805176 1137.046875,342.008270
            	C1137.576538,343.207489 1138.983521,344.848297 1140.012329,344.869659
            	C1150.279907,345.082947 1160.553589,344.999115 1171.633789,344.999115
            	C1169.350220,339.833405 1167.394409,335.307007 1165.356567,330.817871
            	C1158.664062,316.074768 1151.861816,301.380920 1145.249146,286.602234
            	C1134.780151,263.204315 1124.432495,239.752060 1114.029175,216.324753
            	C1106.679321,199.773743 1099.268188,183.249649 1092.020142,166.654236
            	C1090.775757,163.804901 1089.090942,162.819885 1086.021606,162.931107
            	C1079.701050,163.160126 1073.361328,163.146149 1067.039429,162.936432
            	C1064.017944,162.836212 1062.216919,163.687317 1060.984009,166.593155
            	C1056.728149,176.623154 1052.268066,186.566818 1047.850708,196.527832
            	C1042.914795,207.658371 1037.986816,218.792786 1032.972290,229.887939
            	C1026.368286,244.499802 1019.683044,259.074982 1012.959961,274.448792
            M1317.470215,339.023651
            	C1322.769531,326.688293 1328.068848,314.352966 1333.326782,302.113953
            	C1362.282959,302.113953 1390.918701,302.113953 1419.425659,302.113953
            	C1425.153564,315.823242 1430.779907,329.407959 1436.578247,342.918823
            	C1436.994629,343.888580 1438.659668,344.894043 1439.758423,344.910248
            	C1450.016235,345.061462 1460.277100,344.999786 1471.127441,344.999786
            	C1443.845093,283.994904 1416.773315,223.461197 1389.781616,163.106369
            	C1380.297241,163.106369 1371.313721,163.106369 1362.188721,163.106369
            	C1335.116699,223.636734 1308.060913,284.130951 1280.837402,345.000000
            	C1290.822998,345.000000 1299.994141,344.744019 1309.140259,345.110962
            	C1313.533081,345.287231 1316.375854,344.404480 1317.470215,339.023651
            M258.000000,206.500000
            	C258.000000,227.832779 257.706329,249.171494 258.136505,270.495605
            	C258.323029,279.742279 259.111816,289.165985 261.203400,298.141937
            	C264.571503,312.596039 272.330261,324.667908 285.020874,332.975647
            	C298.459900,341.773285 313.646210,344.531250 329.344604,344.921021
            	C335.415619,345.071747 341.514496,344.414612 347.590546,343.982605
            	C359.219543,343.155823 370.321930,339.800537 379.850250,333.402100
            	C398.830963,320.656158 407.407257,301.516510 407.813110,279.323883
            	C408.495575,242.003860 408.002563,204.662292 407.990112,167.329788
            	C407.989655,165.908356 407.843933,164.486954 407.781708,163.353271
            	C396.557861,163.353271 385.815735,163.353271 375.000000,163.353271
            	C375.000000,194.368408 375.077759,225.030670 374.954102,255.692108
            	C374.915466,265.272095 374.770935,274.891357 373.869202,284.416351
            	C373.065002,292.911102 369.609192,300.771240 363.528168,306.831207
            	C350.998627,319.317474 335.346832,320.023071 319.362427,316.578156
            	C305.700806,313.633820 296.654419,304.801880 293.095093,291.171692
            	C291.468628,284.943420 290.187592,278.420502 290.134766,272.019775
            	C289.850006,237.522873 290.000000,203.022415 290.000000,168.523178
            	C290.000000,166.756302 290.000000,164.989410 290.000000,163.284882
            	C279.029907,163.284882 268.623779,163.284882 258.000000,163.284882
            	C258.000000,177.545837 258.000000,191.522919 258.000000,206.500000
            M1257.500000,163.000000
            	C1222.074707,163.000000 1186.649414,163.000000 1151.286133,163.000000
            	C1151.286133,172.641495 1151.286133,181.713730 1151.286133,191.176956
            	C1170.642700,191.176956 1189.716064,191.176956 1209.174438,191.176956
            	C1209.174438,242.641556 1209.174438,293.709167 1209.174438,344.713654
            	C1220.641479,344.713654 1231.713257,344.713654 1243.172119,344.713654
            	C1243.172119,293.360809 1243.172119,242.293198 1243.172119,190.830353
            	C1262.635254,190.830353 1281.708618,190.830353 1300.713135,190.830353
            	C1300.713135,181.357254 1300.713135,172.284988 1300.713135,163.000000
            	C1286.451538,163.000000 1272.475830,163.000000 1257.500000,163.000000
            M2824.000000,179.484528
            	C2824.000000,174.054749 2824.000000,168.624954 2824.000000,163.290558
            	C2773.678467,163.290558 2723.944580,163.290558 2674.293701,163.290558
            	C2674.293701,172.656403 2674.293701,181.728683 2674.293701,191.191864
            	C2693.657959,191.191864 2712.731445,191.191864 2732.190430,191.191864
            	C2732.190430,242.657120 2732.190430,293.724945 2732.190430,344.705383
            	C2743.658203,344.705383 2754.730469,344.705383 2766.189453,344.705383
            	C2766.189453,293.343964 2766.189453,242.276123 2766.189453,190.812836
            	C2785.653320,190.812836 2804.726807,190.812836 2824.000000,190.812836
            	C2824.000000,187.104660 2824.000000,183.791428 2824.000000,179.484528
            M487.000000,300.500000
            	C487.000000,315.262390 487.000000,330.024811 487.000000,344.710663
            	C498.314270,344.710663 509.052917,344.710663 520.178101,344.710663
            	C520.178101,293.354797 520.178101,242.287155 520.178101,190.824402
            	C539.974670,190.824402 559.381287,190.824402 578.710205,190.824402
            	C578.710205,181.351227 578.710205,172.278839 578.710205,163.286118
            	C528.353271,163.286118 478.286591,163.286118 428.289062,163.286118
            	C428.289062,172.647339 428.289062,181.719696 428.289062,191.182648
            	C447.981842,191.182648 467.388428,191.182648 487.000000,191.182648
            	C487.000000,227.553513 487.000000,263.526764 487.000000,300.500000
            M2377.500000,163.000000
            	C2372.068604,163.000000 2366.636963,163.000000 2361.285645,163.000000
            	C2361.285645,223.977570 2361.285645,284.377960 2361.285645,344.711304
            	C2372.312500,344.711304 2383.051270,344.711304 2393.714111,344.711304
            	C2393.714111,284.021790 2393.714111,223.621414 2393.714111,163.000000
            	C2388.452637,163.000000 2383.475342,163.000000 2377.500000,163.000000
            M2619.000000,277.500000
            	C2619.000000,299.927795 2619.000000,322.355621 2619.000000,344.710693
            	C2630.313721,344.710693 2641.052490,344.710693 2651.713867,344.710693
            	C2651.713867,284.020752 2651.713867,223.620544 2651.713867,163.289276
            	C2640.686279,163.289276 2629.947510,163.289276 2619.000000,163.289276
            	C2619.000000,201.218781 2619.000000,238.859390 2619.000000,277.500000
            M706.555359,120.002533
            	C702.725037,120.001686 698.808044,120.516869 695.082520,119.891563
            	C687.548279,118.626976 682.905884,121.702324 679.314392,128.082718
            	C675.415955,135.008392 670.875244,141.572525 666.167358,149.000000
            	C669.914673,149.000000 672.583496,148.731476 675.177002,149.059448
            	C679.689636,149.630112 682.952271,148.136353 685.954773,144.680679
            	C691.261414,138.573135 697.080933,132.914139 702.520386,126.917374
            	C704.388123,124.858292 705.761108,122.350372 706.555359,120.002533
            z`}
            />
            <path
                fill={tipo === 0 ? theme.palette.primary.main : '#FFFFFF'}
                opacity="1.000000"
                stroke="none"
                d={`
            M1833.531372,0.999988
            	C1834.820190,1.666397 1835.578003,2.812621 1836.469727,2.928666
            	C1855.751221,5.437521 1874.167603,11.025572 1892.022827,18.539585
            	C1908.267700,25.375937 1923.502441,34.016888 1937.437622,44.858013
            	C1942.312744,48.650681 1947.250610,52.384995 1951.893799,56.449734
            	C1968.448853,70.942459 1982.425171,87.608200 1994.077148,106.305046
            	C2005.548828,124.712624 2014.389038,144.268356 2019.851929,165.206360
            	C2022.616333,175.802032 2024.590088,186.690872 2025.840454,197.571381
            	C2027.210083,209.490707 2028.162231,221.578171 2027.802368,233.547440
            	C2027.285034,250.747437 2024.751587,267.777313 2020.058472,284.451202
            	C2015.249390,301.537048 2009.146851,318.120148 2000.048340,333.338074
            	C1993.113403,344.937225 1985.360596,356.096100 1977.316406,366.965881
            	C1972.155396,373.939789 1966.221924,380.474762 1959.911255,386.441223
            	C1937.482300,407.646545 1912.237671,424.542206 1882.990112,435.032623
            	C1878.924927,436.490753 1874.919312,438.114075 1870.867188,439.609589
            	C1868.284790,440.562622 1865.988159,440.430511 1865.071899,437.305603
            	C1862.005127,426.844238 1859.161621,416.313507 1855.863037,405.926758
            	C1855.045044,403.351227 1855.146484,402.710449 1857.802124,401.928406
            	C1878.217285,395.916687 1896.800659,386.121429 1913.837646,373.511719
            	C1921.185059,368.073761 1928.327759,362.168304 1934.692505,355.627747
            	C1959.190430,330.453217 1976.134644,300.875031 1983.832520,266.479614
            	C1986.201416,255.894608 1986.914429,244.906921 1987.951172,234.062088
            	C1990.586060,206.497269 1984.708862,180.156281 1974.776733,154.770981
            	C1968.063599,137.613297 1958.302002,121.965385 1946.328491,107.812927
            	C1934.073120,93.327202 1920.499390,80.288803 1904.471802,70.018860
            	C1883.696167,56.706665 1861.313843,47.459423 1836.938965,42.899109
            	C1821.420288,39.995728 1805.770020,39.017750 1790.214966,40.269653
            	C1777.234375,41.314342 1764.441895,44.707771 1751.571411,47.090229
            	C1749.878540,47.403625 1748.217773,47.890450 1746.453857,48.318367
            	C1742.568359,35.588959 1738.852661,23.416130 1734.914307,10.513942
            	C1744.473145,8.285346 1753.658691,6.122359 1762.855835,4.010324
            	C1766.424561,3.190818 1770.019653,2.486550 1773.301392,1.364906
            	C1793.020874,1.000000 1813.041870,1.000000 1833.531372,0.999988
            z`}
            />
            <path
                fill={tipo === 0 ? theme.palette.primary.main : '#FFFFFF'}
                opacity="1.000000"
                stroke="none"
                d={`
            M1772.468628,449.000000
            	C1771.346558,448.334167 1770.751343,447.159058 1770.031372,447.076996
            	C1752.301636,445.055511 1735.407104,439.915405 1718.895142,433.406799
            	C1697.769531,425.079590 1678.361572,413.593048 1660.923462,399.200165
            	C1640.102905,382.015625 1622.638062,361.747528 1608.981201,338.286774
            	C1598.405396,320.118896 1590.704590,300.758209 1585.428223,280.539001
            	C1582.619507,269.775909 1581.102783,258.561768 1580.169067,247.453201
            	C1579.102783,234.767853 1578.484375,221.926636 1579.185181,209.236740
            	C1580.455811,186.226089 1585.898071,163.976089 1594.167236,142.436447
            	C1601.187744,124.149521 1610.782349,107.196388 1622.344971,91.536118
            	C1632.192993,78.197823 1642.993896,65.443161 1656.305908,55.270454
            	C1669.254272,45.375675 1682.475098,35.837524 1696.623535,25.367861
            	C1701.062744,39.753963 1704.986084,52.467934 1708.903442,65.162460
            	C1701.798828,70.381424 1694.239380,75.149323 1687.620605,80.984253
            	C1678.152954,89.330612 1668.693970,97.870346 1660.466187,107.394409
            	C1649.380005,120.227303 1640.798218,134.866669 1633.895142,150.447891
            	C1627.811768,164.179169 1622.930542,178.301437 1621.114746,193.209686
            	C1619.550781,206.050491 1618.030029,219.028854 1618.219971,231.920898
            	C1618.529419,252.908279 1623.271973,273.202484 1630.930298,292.821472
            	C1637.053345,308.507538 1645.115112,323.142090 1655.261597,336.448975
            	C1670.560547,356.513397 1689.099609,373.115753 1711.156128,385.658051
            	C1726.743286,394.521606 1743.178589,401.060760 1760.612671,405.315155
            	C1776.840942,409.275330 1793.261841,410.636505 1809.869019,409.910858
            	C1814.035156,409.728821 1815.947144,410.962006 1817.074463,415.061646
            	C1820.131104,426.176025 1823.654419,437.162079 1826.996338,448.599518
            	C1808.979126,449.000000 1790.958130,449.000000 1772.468628,449.000000
            z`}
            />
            <path
                fill={tipo === 0 ? theme.palette.primary.main : '#FFFFFF'}
                opacity="1.000000"
                stroke="none"
                d={`
            M0.999999,337.444153
            	C2.241805,336.237885 4.127542,335.769165 4.628610,334.669556
            	C11.870649,318.775726 18.867239,302.770416 26.046423,286.847565
            	C31.716879,274.270935 37.626514,261.802216 43.306732,249.229935
            	C53.668560,226.295609 63.908173,203.306107 74.258621,180.366623
            	C81.183723,165.018646 88.211136,149.716843 95.202354,134.398758
            	C95.337532,134.102585 95.717316,133.883682 95.771416,133.589783
            	C98.033623,121.301651 105.800827,118.095322 117.276337,119.868752
            	C122.320480,120.648277 127.603668,119.755081 132.739304,120.140877
            	C134.402359,120.265800 136.746689,121.672844 137.415451,123.122597
            	C146.748901,143.355927 155.841080,163.700485 165.001007,184.013916
            	C176.071732,208.564819 187.137375,233.118011 198.230591,257.658752
            	C207.807434,278.844910 217.408997,300.019897 227.022720,321.189362
            	C230.202301,328.190796 233.472305,335.151215 236.643906,342.156189
            	C236.962097,342.858917 236.883209,343.741425 237.049026,344.998444
            	C223.875137,344.998444 210.928879,345.087769 197.988373,344.855530
            	C196.742340,344.833160 194.983078,343.051819 194.389557,341.684479
            	C187.885117,326.699249 181.568542,311.632568 175.191391,296.592041
            	C174.194199,294.240112 173.634476,291.901215 169.983154,291.915985
            	C135.170319,292.056824 100.356697,292.000061 65.302811,292.000061
            	C57.653816,309.641846 50.025333,327.236328 42.323494,345.000000
            	C28.909990,345.000000 15.423652,345.000000 1.468657,345.000000
            	C1.000000,342.629425 1.000000,340.258881 0.999999,337.444153
            M113.965439,179.359879
            	C113.642235,179.924835 113.261978,180.464996 113.004143,181.058365
            	C105.471458,198.392944 97.907600,215.714188 90.446579,233.079605
            	C86.643730,241.930664 83.029053,250.862564 79.374512,259.649231
            	C106.153343,259.649231 132.518265,259.649231 159.449524,259.649231
            	C157.698257,255.487869 156.170883,251.814377 154.609161,248.155548
            	C149.075928,235.192276 143.535248,222.232208 137.989212,209.274399
            	C132.568604,196.609665 127.157448,183.940735 121.670425,171.304794
            	C121.281204,170.408463 120.301903,169.768356 119.596977,169.009109
            	C118.867432,169.716904 117.898613,170.300369 117.453911,171.156174
            	C116.200287,173.568619 115.162018,176.092957 113.965439,179.359879
            z`}
            />
            <path
                fill={tipo === 0 ? theme.palette.primary.main : '#FFFFFF'}
                opacity="1.000000"
                stroke="none"
                d={`
            M3128.000000,344.531342
            	C3089.546387,345.000000 3051.093018,345.000000 3012.319824,345.000000
            	C3012.319824,284.360626 3012.319824,223.874741 3012.319824,163.197296
            	C3023.137207,163.197296 3033.869141,163.197296 3044.955078,163.197296
            	C3044.955078,214.434341 3044.955078,265.475922 3044.955078,317.000000
            	C3072.558594,317.000000 3099.810547,317.000000 3127.531250,317.000000
            	C3128.000000,326.020905 3128.000000,335.041779 3128.000000,344.531342
            z`}
            />
            <path
                fill={tipo === 0 ? theme.palette.primary.main : '#FFFFFF'}
                opacity="1.000000"
                stroke="none"
                d={`
            M1771.758545,272.399780
            	C1773.852905,279.563599 1775.642578,286.393616 1777.742065,293.127014
            	C1778.015991,294.005646 1779.879150,294.915771 1781.010742,294.921631
            	C1801.676270,295.028809 1822.366333,295.601868 1842.999390,294.776917
            	C1855.615601,294.272461 1866.996582,288.545502 1876.753296,280.594604
            	C1888.119019,271.332367 1896.121704,259.433105 1899.381592,245.040024
            	C1903.955811,224.843292 1900.629517,205.955307 1887.757202,189.308014
            	C1876.541748,174.803543 1861.894409,165.687317 1843.506592,164.150314
            	C1829.994507,163.020889 1816.363159,163.275940 1802.783081,163.022873
            	C1795.790649,162.892563 1788.793945,163.000000 1781.447144,163.000000
            	C1777.161621,148.176270 1772.876709,133.354614 1768.437744,118.000000
            	C1771.116943,118.000000 1772.928223,118.000000 1774.739380,118.000000
            	C1804.905029,118.000000 1835.070923,117.937889 1865.236328,118.024933
            	C1881.510742,118.071892 1895.120483,124.725471 1907.537476,134.881104
            	C1917.963867,143.408752 1925.742065,153.998535 1931.693848,165.692551
            	C1937.795410,177.681107 1942.834839,190.363480 1943.933716,204.030441
            	C1944.922852,216.333252 1946.518555,228.756760 1945.751343,240.995712
            	C1944.191406,265.884430 1936.750366,289.093506 1921.297852,309.166107
            	C1911.688843,321.648010 1900.076782,331.768738 1884.931396,336.886597
            	C1878.882690,338.930542 1872.402710,340.761871 1866.101562,340.820892
            	C1827.772949,341.179871 1789.438965,340.963867 1751.107178,341.066223
            	C1747.926270,341.074738 1746.736084,340.169067 1745.884155,336.977295
            	C1742.491333,324.269012 1738.610718,311.691711 1735.010132,299.037628
            	C1734.211060,296.229919 1732.448120,295.930389 1729.899048,295.945496
            	C1712.400269,296.049255 1694.900024,295.930725 1677.401611,296.061676
            	C1674.122314,296.086212 1672.763550,295.199432 1671.835693,291.784973
            	C1668.180664,278.335022 1664.025391,265.021027 1659.935913,251.253571
            	C1680.211426,251.253571 1700.271118,251.253571 1720.953613,251.253571
            	C1707.928223,206.656982 1695.051636,162.569824 1682.034424,118.001160
            	C1696.124756,118.001160 1710.060791,117.924637 1723.991455,118.158264
            	C1725.015869,118.175438 1726.513672,120.277779 1726.930786,121.668770
            	C1733.703003,144.252365 1740.309570,166.885529 1747.007568,189.491516
            	C1755.162231,217.014114 1763.367188,244.521835 1771.758545,272.399780
            z`}
            />
            <path
                fill={tipo === 0 ? theme.palette.primary.main : '#FFFFFF'}
                opacity="1.000000"
                stroke="none"
                d={`
            M2325.000244,198.048004
            	C2326.000000,203.545639 2327.411133,208.570068 2327.922607,213.684464
            	C2330.069580,235.149109 2328.879395,256.361176 2322.012207,276.996552
            	C2313.546875,302.434540 2297.222412,321.239197 2273.163086,332.860779
            	C2259.298584,339.557831 2244.435303,343.770935 2228.914062,343.909760
            	C2195.853516,344.205444 2162.788086,344.000122 2129.361084,344.000122
            	C2129.361084,270.102722 2129.361084,195.276138 2129.361084,120.000000
            	C2130.951172,120.000000 2132.565430,120.000000 2134.179932,120.000000
            	C2162.997070,120.000000 2191.818604,119.693253 2220.630127,120.095207
            	C2244.503174,120.428261 2267.004395,126.179222 2286.913818,140.008713
            	C2306.183838,153.394028 2318.222412,171.774429 2324.008789,194.391586
            	C2324.287109,195.479630 2324.665771,196.541931 2325.000244,198.048004
            M2171.000000,217.500000
            	C2171.000000,248.576935 2171.000000,279.653870 2171.000000,311.000000
            	C2183.647217,311.000000 2195.790283,311.379578 2207.896729,310.877960
            	C2217.316895,310.487701 2226.883545,309.861786 2236.046631,307.821381
            	C2255.490234,303.491730 2270.650635,292.868317 2278.908936,274.144958
            	C2283.291016,264.209534 2285.033936,253.635635 2286.264893,242.760620
            	C2287.880127,228.489471 2286.405273,214.667221 2282.801270,201.131027
            	C2279.238770,187.750824 2272.178467,176.287125 2260.563477,167.835632
            	C2246.858643,157.863373 2231.275146,154.603958 2214.919434,154.084457
            	C2201.777100,153.667023 2188.611328,153.992767 2175.455811,154.010345
            	C2174.024658,154.012253 2172.593262,154.157471 2171.000000,154.245117
            	C2171.000000,175.237305 2171.000000,195.868652 2171.000000,217.500000
            z`}
            />
            <path
                fill={tipo === 0 ? theme.palette.primary.main : '#FFFFFF'}
                opacity="1.000000"
                stroke="none"
                d={`
            M909.733887,224.677216
            	C918.312561,204.912567 926.697144,185.485519 934.956238,166.005249
            	C935.919312,163.733765 937.074768,162.874451 939.593689,162.934372
            	C947.225098,163.115891 954.863586,163.000015 962.747925,163.000015
            	C962.747925,223.693542 962.747925,284.098297 962.747925,344.751709
            	C952.967407,344.751709 943.228516,344.751709 932.942810,344.751709
            	C932.942810,309.859711 932.942810,275.019897 932.942810,240.180069
            	C932.407349,240.028168 931.871826,239.876282 931.336365,239.724396
            	C916.515198,274.669098 901.694031,309.613831 886.785339,344.764954
            	C879.382874,344.764954 871.953796,344.764954 864.317017,344.764954
            	C849.089722,309.539185 833.807068,274.185455 818.524475,238.831696
            	C818.182983,238.917786 817.841492,239.003860 817.500000,239.089951
            	C817.500000,274.151978 817.500000,309.214020 817.500000,344.638031
            	C808.203247,344.638031 798.460144,344.638031 788.357666,344.638031
            	C788.357666,284.518890 788.357666,224.113495 788.357666,163.014771
            	C796.649780,163.014771 805.233521,162.972214 813.814880,163.091949
            	C814.536804,163.102005 815.582336,164.056259 815.907593,164.815857
            	C823.662476,182.929688 831.300964,201.093292 839.043579,219.212433
            	C844.398560,231.743988 849.836487,244.240585 855.311951,256.720062
            	C861.496277,270.815186 867.757935,284.876373 874.007690,298.942719
            	C874.496521,300.043060 875.155884,301.067719 876.073975,302.744873
            	C887.409912,276.380310 898.456177,250.689606 909.733887,224.677216
            z`}
            />
            <path
                fill={tipo === 0 ? theme.palette.primary.main : '#FFFFFF'}
                opacity="1.000000"
                stroke="none"
                d={`
            M617.762573,177.731430
            	C629.267456,169.779282 641.623108,165.478058 655.218689,163.876770
            	C672.382263,161.855240 689.095764,162.860291 705.277893,169.036148
            	C720.753601,174.942413 733.027283,184.873505 741.651062,199.171204
            	C751.297729,215.164810 754.904785,232.587326 755.106201,251.114456
            	C755.314758,270.293274 752.694580,288.609558 743.098694,305.589447
            	C736.604736,317.080505 727.818726,326.141998 716.622864,333.068909
            	C705.975647,339.656281 694.386169,342.651978 682.027527,344.131409
            	C661.560486,346.581451 642.186707,343.890686 624.034912,333.974243
            	C609.040527,325.782715 598.731262,313.258118 592.052185,297.740204
            	C584.806702,280.906311 583.053162,263.069153 584.104492,245.025635
            	C585.190247,226.392303 590.086731,208.661316 601.384277,193.511414
            	C605.782959,187.612839 612.074768,183.125931 617.762573,177.731430
            M629.392883,208.083481
            	C628.264099,210.029938 627.054504,211.934525 626.019409,213.929596
            	C618.658752,228.116608 617.163757,243.253601 617.833923,259.015564
            	C618.288513,269.705933 619.839233,280.026703 624.101440,289.736542
            	C628.924866,300.724731 636.545837,309.212006 647.932434,314.085632
            	C656.754456,317.861511 665.690674,318.884644 675.233093,318.125366
            	C690.007263,316.949799 701.381165,310.468384 710.060852,298.634033
            	C719.002014,286.443268 721.158630,272.227356 721.811035,257.822388
            	C722.185913,249.547012 721.220337,241.094177 719.864502,232.885361
            	C717.971313,221.423080 713.650696,210.630600 704.949524,202.626923
            	C691.314209,190.084763 674.911804,186.873917 656.999817,190.473907
            	C645.810181,192.722794 637.248901,199.185196 629.392883,208.083481
            z`}
            />
            <path
                fill={tipo === 0 ? theme.palette.primary.main : '#FFFFFF'}
                opacity="1.000000"
                stroke="none"
                d={`
            M2567.742188,207.703018
            	C2558.284180,201.763458 2549.057861,195.662231 2538.220459,192.660629
            	C2522.062744,188.185593 2505.828613,187.927200 2490.317627,194.464005
            	C2475.767334,200.595795 2468.177979,213.006744 2463.961426,227.810272
            	C2460.665039,239.383453 2460.175537,251.249832 2461.177246,262.995575
            	C2462.556152,279.165131 2466.273926,294.654053 2479.497803,306.100006
            	C2487.230957,312.793121 2496.195312,316.270020 2506.009521,317.973938
            	C2520.466064,320.483765 2534.637207,318.212891 2548.680664,314.898376
            	C2549.961914,314.596008 2551.839600,312.860962 2551.856934,311.766327
            	C2552.073975,297.981537 2551.999268,284.192108 2551.999268,270.006836
            	C2539.212158,270.006836 2526.801758,270.006836 2514.200684,270.006836
            	C2514.200684,261.868225 2514.200684,254.130234 2514.200684,246.203766
            	C2537.138916,246.203766 2559.876465,246.203766 2583.000000,246.203766
            	C2583.000000,256.858276 2583.000000,267.313812 2583.000000,277.769318
            	C2583.000000,294.434967 2582.890869,311.101898 2583.092041,327.765137
            	C2583.132324,331.102020 2582.185059,333.111816 2579.067383,333.978973
            	C2569.342529,336.683777 2559.744141,340.272156 2549.832764,341.768707
            	C2536.896729,343.721832 2523.677246,345.117188 2510.635254,344.754425
            	C2487.516357,344.111389 2466.295166,337.172821 2449.681641,320.077057
            	C2438.228516,308.291473 2431.531250,293.751526 2429.190918,277.653259
            	C2427.540771,266.302979 2426.497803,254.617905 2427.244385,243.217209
            	C2428.782715,219.713287 2436.694336,198.494003 2455.043457,182.568497
            	C2465.806641,173.226929 2478.557861,167.201126 2492.646729,165.173019
            	C2503.347412,163.632645 2514.323486,162.683121 2525.090332,163.216675
            	C2544.092041,164.158310 2561.835938,169.806610 2577.071777,181.754272
            	C2578.054443,182.524918 2578.695068,185.050156 2578.193115,186.181763
            	C2575.007812,193.362305 2571.456299,200.380280 2567.742188,207.703018
            z`}
            />
            <path
                fill={tipo === 0 ? theme.palette.primary.main : '#FFFFFF'}
                opacity="1.000000"
                stroke="none"
                d={`
            M2954.730957,332.317780
            	C2950.313721,322.099915 2946.130371,312.202301 2941.818359,302.000000
            	C2937.321533,302.000000 2932.866943,302.000000 2928.412109,302.000000
            	C2905.592285,302.000000 2882.771973,302.057831 2859.952881,301.931610
            	C2856.873779,301.914581 2855.163574,302.804810 2853.987305,305.702515
            	C2848.933594,318.153229 2843.744629,330.550262 2838.442383,342.897003
            	C2838.023682,343.871826 2836.367432,344.893097 2835.271973,344.909241
            	C2824.959229,345.061554 2814.643311,344.999756 2803.422363,344.999756
            	C2805.700928,339.859436 2807.726807,335.246948 2809.786865,330.649811
            	C2817.521973,313.388062 2825.303223,296.146820 2833.002197,278.868866
            	C2844.863525,252.250183 2856.647705,225.597321 2868.512695,198.980209
            	C2873.277100,188.291473 2878.262939,177.700394 2882.940430,166.974197
            	C2884.228516,164.020157 2885.794922,162.786362 2889.158447,162.910812
            	C2896.806885,163.193756 2904.472900,163.000000 2912.276367,163.000000
            	C2939.322754,223.474121 2966.363525,283.935913 2993.673340,344.999451
            	C2982.655029,344.999451 2972.515137,345.081757 2962.380615,344.885437
            	C2961.236328,344.863251 2959.666504,343.502563 2959.082275,342.359558
            	C2957.484375,339.234222 2956.307861,335.893494 2954.730957,332.317780
            M2899.954102,202.613556
            	C2898.977051,203.385056 2897.528320,203.952621 2897.093262,204.958572
            	C2890.673828,219.801056 2884.370361,234.693924 2878.079590,249.591736
            	C2874.407715,258.288147 2870.807129,267.014526 2867.128174,275.838745
            	C2888.237549,275.838745 2909.018555,275.838745 2930.728516,275.838745
            	C2920.366943,251.385788 2910.202393,227.396942 2899.954102,202.613556
            z`}
            />
            <path
                fill={tipo === 0 ? theme.palette.primary.main : '#FFFFFF'}
                opacity="1.000000"
                stroke="none"
                d={`
            M1012.996338,274.057373
            	C1019.683044,259.074982 1026.368286,244.499802 1032.972290,229.887939
            	C1037.986816,218.792786 1042.914795,207.658371 1047.850708,196.527832
            	C1052.268066,186.566818 1056.728149,176.623154 1060.984009,166.593155
            	C1062.216919,163.687317 1064.017944,162.836212 1067.039429,162.936432
            	C1073.361328,163.146149 1079.701050,163.160126 1086.021606,162.931107
            	C1089.090942,162.819885 1090.775757,163.804901 1092.020142,166.654236
            	C1099.268188,183.249649 1106.679321,199.773743 1114.029175,216.324753
            	C1124.432495,239.752060 1134.780151,263.204315 1145.249146,286.602234
            	C1151.861816,301.380920 1158.664062,316.074768 1165.356567,330.817871
            	C1167.394409,335.307007 1169.350220,339.833405 1171.633789,344.999115
            	C1160.553589,344.999115 1150.279907,345.082947 1140.012329,344.869659
            	C1138.983521,344.848297 1137.576538,343.207489 1137.046875,342.008270
            	C1131.215942,328.805176 1125.524292,315.540558 1119.668213,302.000000
            	C1102.622559,302.000000 1085.496460,302.000000 1068.370239,302.000000
            	C1058.210693,302.000000 1048.049438,302.103302 1037.892700,301.933441
            	C1035.055786,301.886017 1033.325562,302.586853 1032.199463,305.327209
            	C1027.210083,317.470123 1022.143066,329.582703 1016.908264,341.621063
            	C1016.300659,343.018372 1014.499695,344.819214 1013.211548,344.852264
            	C1002.905518,345.116455 992.589783,344.999817 981.321228,344.999817
            	C992.041382,321.094940 1002.500671,297.771851 1012.996338,274.057373
            M1107.982910,274.828186
            	C1097.929688,250.737640 1087.876343,226.647095 1077.823120,202.556534
            	C1077.255371,202.640854 1076.687622,202.725174 1076.119873,202.809494
            	C1065.779297,227.084412 1055.438843,251.359314 1045.074951,275.689240
            	C1066.237671,275.689240 1087.015869,275.689240 1107.982910,274.828186
            z`}
            />
            <path
                fill={tipo === 0 ? theme.palette.primary.main : '#FFFFFF'}
                opacity="1.000000"
                stroke="none"
                d={`
            M1317.249512,339.361755
            	C1316.375854,344.404480 1313.533081,345.287231 1309.140259,345.110962
            	C1299.994141,344.744019 1290.822998,345.000000 1280.837402,345.000000
            	C1308.060913,284.130951 1335.116699,223.636734 1362.188721,163.106369
            	C1371.313721,163.106369 1380.297241,163.106369 1389.781616,163.106369
            	C1416.773315,223.461197 1443.845093,283.994904 1471.127441,344.999786
            	C1460.277100,344.999786 1450.016235,345.061462 1439.758423,344.910248
            	C1438.659668,344.894043 1436.994629,343.888580 1436.578247,342.918823
            	C1430.779907,329.407959 1425.153564,315.823242 1419.425659,302.113953
            	C1390.918701,302.113953 1362.282959,302.113953 1333.326782,302.113953
            	C1328.068848,314.352966 1322.769531,326.688293 1317.249512,339.361755
            M1350.677734,276.000000
            	C1369.724121,276.000000 1388.770508,276.000000 1408.338013,276.000000
            	C1398.040894,251.805817 1388.032837,228.272003 1377.977173,204.758591
            	C1377.681641,204.067444 1376.883545,203.591232 1376.319702,203.014862
            	C1375.628296,203.796097 1374.672729,204.464661 1374.284180,205.375122
            	C1366.689941,223.178192 1359.153687,241.006088 1351.624634,258.836914
            	C1349.284912,264.377930 1347.001221,269.942627 1344.482544,276.000000
            	C1346.762573,276.000000 1348.246338,276.000000 1350.677734,276.000000
            z`}
            />
            <path
                fill={tipo === 0 ? theme.palette.primary.main : '#FFFFFF'}
                opacity="1.000000"
                stroke="none"
                d={`
            M258.000000,206.000000
            	C258.000000,191.522919 258.000000,177.545837 258.000000,163.284882
            	C268.623779,163.284882 279.029907,163.284882 290.000000,163.284882
            	C290.000000,164.989410 290.000000,166.756302 290.000000,168.523178
            	C290.000000,203.022415 289.850006,237.522873 290.134766,272.019775
            	C290.187592,278.420502 291.468628,284.943420 293.095093,291.171692
            	C296.654419,304.801880 305.700806,313.633820 319.362427,316.578156
            	C335.346832,320.023071 350.998627,319.317474 363.528168,306.831207
            	C369.609192,300.771240 373.065002,292.911102 373.869202,284.416351
            	C374.770935,274.891357 374.915466,265.272095 374.954102,255.692108
            	C375.077759,225.030670 375.000000,194.368408 375.000000,163.353271
            	C385.815735,163.353271 396.557861,163.353271 407.781708,163.353271
            	C407.843933,164.486954 407.989655,165.908356 407.990112,167.329788
            	C408.002563,204.662292 408.495575,242.003860 407.813110,279.323883
            	C407.407257,301.516510 398.830963,320.656158 379.850250,333.402100
            	C370.321930,339.800537 359.219543,343.155823 347.590546,343.982605
            	C341.514496,344.414612 335.415619,345.071747 329.344604,344.921021
            	C313.646210,344.531250 298.459900,341.773285 285.020874,332.975647
            	C272.330261,324.667908 264.571503,312.596039 261.203400,298.141937
            	C259.111816,289.165985 258.323029,279.742279 258.136505,270.495605
            	C257.706329,249.171494 258.000000,227.832779 258.000000,206.000000
            z`}
            />
            <path
                fill={tipo === 0 ? theme.palette.primary.main : '#FFFFFF'}
                opacity="1.000000"
                stroke="none"
                d={`
            M1258.000000,163.000000
            	C1272.475830,163.000000 1286.451538,163.000000 1300.713135,163.000000
            	C1300.713135,172.284988 1300.713135,181.357254 1300.713135,190.830353
            	C1281.708618,190.830353 1262.635254,190.830353 1243.172119,190.830353
            	C1243.172119,242.293198 1243.172119,293.360809 1243.172119,344.713654
            	C1231.713257,344.713654 1220.641479,344.713654 1209.174438,344.713654
            	C1209.174438,293.709167 1209.174438,242.641556 1209.174438,191.176956
            	C1189.716064,191.176956 1170.642700,191.176956 1151.286133,191.176956
            	C1151.286133,181.713730 1151.286133,172.641495 1151.286133,163.000000
            	C1186.649414,163.000000 1222.074707,163.000000 1258.000000,163.000000
            z`}
            />
            <path
                fill={tipo === 0 ? theme.palette.primary.main : '#FFFFFF'}
                opacity="1.000000"
                stroke="none"
                d={`
            M2824.000000,179.981354
            	C2824.000000,183.791428 2824.000000,187.104660 2824.000000,190.812836
            	C2804.726807,190.812836 2785.653320,190.812836 2766.189453,190.812836
            	C2766.189453,242.276123 2766.189453,293.343964 2766.189453,344.705383
            	C2754.730469,344.705383 2743.658203,344.705383 2732.190430,344.705383
            	C2732.190430,293.724945 2732.190430,242.657120 2732.190430,191.191864
            	C2712.731445,191.191864 2693.657959,191.191864 2674.293701,191.191864
            	C2674.293701,181.728683 2674.293701,172.656403 2674.293701,163.290558
            	C2723.944580,163.290558 2773.678467,163.290558 2824.000000,163.290558
            	C2824.000000,168.624954 2824.000000,174.054749 2824.000000,179.981354
            z`}
            />
            <path
                fill={tipo === 0 ? theme.palette.primary.main : '#FFFFFF'}
                opacity="1.000000"
                stroke="none"
                d={`
            M487.000000,300.000000
            	C487.000000,263.526764 487.000000,227.553513 487.000000,191.182648
            	C467.388428,191.182648 447.981842,191.182648 428.289062,191.182648
            	C428.289062,181.719696 428.289062,172.647339 428.289062,163.286118
            	C478.286591,163.286118 528.353271,163.286118 578.710205,163.286118
            	C578.710205,172.278839 578.710205,181.351227 578.710205,190.824402
            	C559.381287,190.824402 539.974670,190.824402 520.178101,190.824402
            	C520.178101,242.287155 520.178101,293.354797 520.178101,344.710663
            	C509.052917,344.710663 498.314270,344.710663 487.000000,344.710663
            	C487.000000,330.024811 487.000000,315.262390 487.000000,300.000000
            z`}
            />
            <path
                fill={tipo === 0 ? theme.palette.primary.main : '#FFFFFF'}
                opacity="1.000000"
                stroke="none"
                d={`
            M2377.999023,163.000000
            	C2383.475342,163.000000 2388.452637,163.000000 2393.714111,163.000000
            	C2393.714111,223.621414 2393.714111,284.021790 2393.714111,344.711304
            	C2383.051270,344.711304 2372.312500,344.711304 2361.285645,344.711304
            	C2361.285645,284.377960 2361.285645,223.977570 2361.285645,163.000000
            	C2366.636963,163.000000 2372.068604,163.000000 2377.999023,163.000000
            z`}
            />
            <path
                fill={tipo === 0 ? theme.palette.primary.main : '#FFFFFF'}
                opacity="1.000000"
                stroke="none"
                d={`
            M2619.000000,277.000000
            	C2619.000000,238.859390 2619.000000,201.218781 2619.000000,163.289276
            	C2629.947510,163.289276 2640.686279,163.289276 2651.713867,163.289276
            	C2651.713867,223.620544 2651.713867,284.020752 2651.713867,344.710693
            	C2641.052490,344.710693 2630.313721,344.710693 2619.000000,344.710693
            	C2619.000000,322.355621 2619.000000,299.927795 2619.000000,277.000000
            z`}
            />
            <path
                fill={tipo === 0 ? theme.palette.primary.main : '#FFFFFF'}
                opacity="1.000000"
                stroke="none"
                d={`
            M706.957764,120.025024
            	C705.761108,122.350372 704.388123,124.858292 702.520386,126.917374
            	C697.080933,132.914139 691.261414,138.573135 685.954773,144.680679
            	C682.952271,148.136353 679.689636,149.630112 675.177002,149.059448
            	C672.583496,148.731476 669.914673,149.000000 666.167358,149.000000
            	C670.875244,141.572525 675.415955,135.008392 679.314392,128.082718
            	C682.905884,121.702324 687.548279,118.626976 695.082520,119.891563
            	C698.808044,120.516869 702.725037,120.001686 706.957764,120.025024
            z`}
            />
            <path
                fill={theme.palette.primary.main}
                opacity="1.000000"
                stroke="none"
                d={`
            M114.002151,178.966537
            	C115.162018,176.092957 116.200287,173.568619 117.453911,171.156174
            	C117.898613,170.300369 118.867432,169.716904 119.596977,169.009109
            	C120.301903,169.768356 121.281204,170.408463 121.670425,171.304794
            	C127.157448,183.940735 132.568604,196.609665 137.989212,209.274399
            	C143.535248,222.232208 149.075928,235.192276 154.609161,248.155548
            	C156.170883,251.814377 157.698257,255.487869 159.449524,259.649231
            	C132.518265,259.649231 106.153343,259.649231 79.374512,259.649231
            	C83.029053,250.862564 86.643730,241.930664 90.446579,233.079605
            	C97.907600,215.714188 105.471458,198.392944 113.004143,181.058365
            	C113.261978,180.464996 113.642235,179.924835 114.002151,178.966537
            z`}
            />
            <path
                fill={theme.palette.primary.main}
                opacity="1.000000"
                stroke="none"
                d={`
            M2171.000000,217.000000
            	C2171.000000,195.868652 2171.000000,175.237305 2171.000000,154.245117
            	C2172.593262,154.157471 2174.024658,154.012253 2175.455811,154.010345
            	C2188.611328,153.992767 2201.777100,153.667023 2214.919434,154.084457
            	C2231.275146,154.603958 2246.858643,157.863373 2260.563477,167.835632
            	C2272.178467,176.287125 2279.238770,187.750824 2282.801270,201.131027
            	C2286.405273,214.667221 2287.880127,228.489471 2286.264893,242.760620
            	C2285.033936,253.635635 2283.291016,264.209534 2278.908936,274.144958
            	C2270.650635,292.868317 2255.490234,303.491730 2236.046631,307.821381
            	C2226.883545,309.861786 2217.316895,310.487701 2207.896729,310.877960
            	C2195.790283,311.379578 2183.647217,311.000000 2171.000000,311.000000
            	C2171.000000,279.653870 2171.000000,248.576935 2171.000000,217.000000
            z`}
            />
            <path
                fill={theme.palette.primary.main}
                opacity="1.000000"
                stroke="none"
                d={`
            M629.665527,207.839386
            	C637.248901,199.185196 645.810181,192.722794 656.999817,190.473907
            	C674.911804,186.873917 691.314209,190.084763 704.949524,202.626923
            	C713.650696,210.630600 717.971313,221.423080 719.864502,232.885361
            	C721.220337,241.094177 722.185913,249.547012 721.811035,257.822388
            	C721.158630,272.227356 719.002014,286.443268 710.060852,298.634033
            	C701.381165,310.468384 690.007263,316.949799 675.233093,318.125366
            	C665.690674,318.884644 656.754456,317.861511 647.932434,314.085632
            	C636.545837,309.212006 628.924866,300.724731 624.101440,289.736542
            	C619.839233,280.026703 618.288513,269.705933 617.833923,259.015564
            	C617.163757,243.253601 618.658752,228.116608 626.019409,213.929596
            	C627.054504,211.934525 628.264099,210.029938 629.665527,207.839386
            z`}
            />
            <path
                fill={theme.palette.primary.main}
                opacity="1.000000"
                stroke="none"
                d={`
            M2899.996094,203.010834
            	C2910.202393,227.396942 2920.366943,251.385788 2930.728516,275.838745
            	C2909.018555,275.838745 2888.237549,275.838745 2867.128174,275.838745
            	C2870.807129,267.014526 2874.407715,258.288147 2878.079590,249.591736
            	C2884.370361,234.693924 2890.673828,219.801056 2897.093262,204.958572
            	C2897.528320,203.952621 2898.977051,203.385056 2899.996094,203.010834
            z`}
            />
            <path
                fill={theme.palette.primary.main}
                opacity="1.000000"
                stroke="none"
                d={`
            M1107.888428,275.258728
            	C1087.015869,275.689240 1066.237671,275.689240 1045.074951,275.689240
            	C1055.438843,251.359314 1065.779297,227.084412 1076.119873,202.809494
            	C1076.687622,202.725174 1077.255371,202.640854 1077.823120,202.556534
            	C1087.876343,226.647095 1097.929688,250.737640 1107.888428,275.258728
            z`}
            />
            <path
                fill={theme.palette.primary.main}
                opacity="1.000000"
                stroke="none"
                d={`
            M1350.203857,276.000000
            	C1348.246338,276.000000 1346.762573,276.000000 1344.482544,276.000000
            	C1347.001221,269.942627 1349.284912,264.377930 1351.624634,258.836914
            	C1359.153687,241.006088 1366.689941,223.178192 1374.284180,205.375122
            	C1374.672729,204.464661 1375.628296,203.796097 1376.319702,203.014847
            	C1376.883545,203.591232 1377.681641,204.067444 1377.977173,204.758591
            	C1388.032837,228.272003 1398.040894,251.805817 1408.338013,276.000000
            	C1388.770508,276.000000 1369.724121,276.000000 1350.203857,276.000000
            z`}
            />
        </svg>
    );
};

export default LogoAD;
