import { useEffect, useState, lazy } from 'react';

// material-ui
import { Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Loadable from 'ui-component/Loadable';
// project imports
import EarningCard from './EarningCard';
import PopularCard from './PopularCard';
import TotalOrderLineChartCard from './TotalOrderLineChartCard';
import TotalIncomeDarkCard from './TotalIncomeDarkCard';
import TotalIncomeLightCard from './TotalIncomeLightCard';
import TotalGrowthBarChart from './TotalGrowthBarChart';
import { gridSpacing } from 'store/constant';
import { UseUserState, SetListaActual, UseUserDispatch, SetCargarMas, SetBandejaActual } from 'context/UserContext';

import { CargarActual } from 'context/funciones/bandejas';
// preferencias
import Preferencias from './Preferencias';
// etiquetas
import EtiquetaUser from './Etiquetas/EtiquetaUser';
import PerfectScrollbar from 'react-perfect-scrollbar';

// import ListaUsuarios from './ListaUsuarios';
// import Chat from './ChatUser/Chat';
import Atributos from './UserList/Atributos';
import DialogNotification from './DialogNotification';
// ==============================|| DEFAULT DASHBOARD ||============================== //
const Chat = Loadable(lazy(() => import('./ChatUser/Chat')));
const ListaUsuarios = Loadable(lazy(() => import('./ListaUsuarios')));

const Dashboard = () => {
    const { preferencias, mostrarChat, listaUsuarios, bandejaActual, limiteChat, listaUsuariosActual, tableHashUser, showAtributos } =
        UseUserState();
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const userDispatch = UseUserDispatch();

    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(false);
        // SetBandejaActual(userDispatch, { title: 'chatcenter', id: 0 });
    }, []);

    useEffect(() => {
        // cargar bandeja actual
        // console.log('listaActual');
        CargarActual(listaUsuarios, [], bandejaActual, 0, limiteChat, SetListaActual, SetCargarMas, userDispatch, tableHashUser, null);
    }, [bandejaActual]);

    return (
        <Grid container spacing={gridSpacing} sx={{ height: 'calc(100vh - 88px)' }}>
            <Preferencias />
            <EtiquetaUser />
            <DialogNotification />
            <Grid
                item
                xs={12}
                md={4}
                sx={{
                    [theme.breakpoints.down('md')]: {
                        display: mostrarChat ? 'block' : 'none'
                    }
                }}
            >
                <PerfectScrollbar
                    component="div"
                    style={{
                        paddingLeft: '1px',
                        paddingRight: '1px'
                    }}
                >
                    <ListaUsuarios />
                </PerfectScrollbar>
            </Grid>
            <Grid
                item
                xs={12}
                md={8}
                sx={{
                    [theme.breakpoints.down('md')]: {
                        color: 'black',
                        display: mostrarChat ? 'none' : 'block'
                    }
                }}
            >
                <PerfectScrollbar
                    component="div"
                    style={{
                        paddingLeft: '1px',
                        paddingRight: '1px'
                    }}
                >
                    <div>
                        <Chat />
                    </div>
                </PerfectScrollbar>
            </Grid>
            {showAtributos && <Atributos />}
        </Grid>
    );
};

export default Dashboard;
