import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
// project import
import useScriptRef from 'hooks/useScriptRef';
import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { OutlinedInput, Box, FormControl, InputLabel, FormHelperText, Stack, Typography, Grid } from '@mui/material';
import FiltroEtiquetas from './FiltroEtiquetas';
import {
    UseUserDispatch,
    Logaout,
    UseUserState,
    MostrarPreferencias,
    CambiarPreferencias,
    EtiquetasUSer,
    GuatadarEtiquetasUsuarioContext
} from 'context/UserContext';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

export default function EtiquetaUser() {
    const theme = useTheme();
    const { preferencias, datos, user, etiquetasUser, listaEtiqetas, etiquetasUserID } = UseUserState();
    const userDispatch = UseUserDispatch();
    const scriptedRef = useScriptRef();
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        EtiquetasUSer(userDispatch, false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };

    return (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={etiquetasUser}>
            <MainCard
                sx={{
                    maxWidth: { xs: 400, lg: 475 },
                    margin: { xs: 2.5, md: 3 },
                    '& > *': {
                        flexGrow: 1,
                        flexBasis: '50%'
                    }
                }}
                content={false}
            >
                <Box sx={{ p: { xs: 2, sm: 3, xl: 5 } }}>
                    <Grid container direction="column" justifyContent="center" spacing={2}>
                        <Grid item xs={12} container alignItems="center" justifyContent="center">
                            <Box sx={{ mb: 2 }}>
                                <Typography variant="h3">Etiquetas</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} container alignItems="center" justifyContent="center">
                            <Box sx={{ mb: 2 }}>
                                <Typography variant="subtitle1">Por favor seleccione las etiquetas que desea asiganar </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Formik
                        initialValues={{
                            etiquetas: [],
                            submit: null
                        }}
                        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                            try {
                                console.log('se inicio sesion', values.etiquetas);
                                // CambiarPreferencias(values.red, values.bot, user, datos);
                                GuatadarEtiquetasUsuarioContext(datos, values.etiquetas, etiquetasUserID, handleClose, '');
                                setStatus({ success: true });
                                setSubmitting(false);
                            } catch (err) {
                                console.error(err);
                                if (scriptedRef.current) {
                                    setStatus({ success: false });
                                    setErrors({ submit: err.message });
                                    setSubmitting(false);
                                }
                            }
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.etiquetas && errors.etiquetas)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <FiltroEtiquetas value={values.etiquetas} setVal={setFieldValue} />
                                    {touched.etiquetas && errors.etiquetas && (
                                        <FormHelperText error id="standard-weight-helper-text-email-login">
                                            {errors.etiquetas}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                {errors.submit && (
                                    <Box sx={{ mt: 3 }}>
                                        <FormHelperText error>{errors.submit}</FormHelperText>
                                    </Box>
                                )}
                                {/* type="submit" */}
                                <Box sx={{ mt: 2 }}>
                                    <AnimateButton>
                                        <Button
                                            disableElevation
                                            disabled={isSubmitting}
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                        >
                                            Guardar
                                        </Button>
                                    </AnimateButton>
                                    <AnimateButton>
                                        <Button
                                            disableElevation
                                            fullWidth
                                            size="large"
                                            onClick={handleClose}
                                            variant="contained"
                                            color="secondary"
                                        >
                                            Cancelar
                                        </Button>
                                    </AnimateButton>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </MainCard>
        </Backdrop>
    );
}
