import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { UseUserDispatch, UseUserState, NuevaEtiquetaContext } from 'context/UserContext';

export default function NuevaEtiqueta({ open, toggleOpen, listaValor, setListaValor, dialogValue, setDialogValue }) {
    const { datos } = UseUserState();
    // const [dialogValue, setDialogValue] = React.useState({ nombre: '', color: '' });
    const [cargando, setCargando] = React.useState(false);

    const handleClose = () => {
        // toggleOpen(false);
        setDialogValue({ nombre: '', color: '' });
    };

    const Close = () => {
        toggleOpen(false);
        setDialogValue({ nombre: '', color: '' });
    };

    const CopiarValor = (valor) => valor;

    const NuevoValor = () => {
        if (dialogValue.nombre.length > 0 && dialogValue.color.length) {
            NuevaEtiquetaContext(datos, dialogValue, toggleOpen, setCargando, CopiarValor(listaValor), setListaValor);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Nueva etiqueta</DialogTitle>
            <DialogContent>
                <DialogContentText>Ingrese le nombre y el color para la nueva etiqueta</DialogContentText>
                <TextField
                    fullWidth
                    autoFocus
                    margin="dense"
                    id="name"
                    value={dialogValue.nombre}
                    onChange={(event) =>
                        setDialogValue({
                            ...dialogValue,
                            nombre: event.target.value
                        })
                    }
                    label="Titulo"
                    type="text"
                    variant="standard"
                    disabled={cargando}
                />
                <TextField
                    fullWidth
                    id="name"
                    value={dialogValue.color}
                    onChange={(event) =>
                        setDialogValue({
                            ...dialogValue,
                            color: event.target.value
                        })
                    }
                    label="Color"
                    type="color"
                    variant="standard"
                    disabled={cargando}
                />
            </DialogContent>
            {cargando ? (
                <DialogActions>
                    <CircularProgress />
                </DialogActions>
            ) : (
                <DialogActions>
                    <Button onClick={Close}>Cancel</Button>
                    <Button onClick={NuevoValor} color="primary">
                        Guardar
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
}
