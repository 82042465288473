import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import {
    getDatabase,
    onValue,
    set,
    push,
    update,
    onDisconnect,
    ref,
    serverTimestamp,
    orderByChild,
    equalTo,
    query,
    limitToFirst,
    limitToLast,
    off,
    onChildChanged,
    onChildAdded,
    onChildRemoved,
    startAfter
} from 'firebase/database';
import { getStorage, ref as refStorage, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { getMessaging } from 'firebase/messaging';
import { onBackgroundMessage } from 'firebase/messaging/sw';

const config = {
    apiKey: 'AIzaSyAqYz0bN3A-FWzXXFMZtMln4Bvj_L6kcNg',
    authDomain: 'avi1-b48f1.firebaseapp.com',
    databaseURL: 'https://avi1-b48f1.firebaseio.com',
    projectId: 'avi1-b48f1',
    storageBucket: 'avi1-b48f1.appspot.com',
    messagingSenderId: '271883633443',
    appId: '1:271883633443:web:54960ff8a626a5e9008296',
    measurementId: 'G-DQ0QPWW0LD'
};

const app = initializeApp(config);

// autenticacion
export const auth = getAuth(app);
export const SignInWithEmailAndPassword = signInWithEmailAndPassword;
export const OnAuthStateChanged = onAuthStateChanged;
export const SignOut = signOut;

// base de datos
export const Basedatos = getDatabase(app);
export const OnValue = onValue;
export const Setdb = set;
export const Updatedb = update;
export const Push = push;
export const OnDisconnect = onDisconnect;
export const Ref = ref;
export const Timestamp = serverTimestamp;
export const OrderByChild = orderByChild;
export const EqualTo = equalTo;
export const Query = query;
export const LimitToFirst = limitToFirst;
export const LimitToLast = limitToLast;
export const Off = off;
export const OnChildChanged = onChildChanged;
export const OnChildAdded = onChildAdded;
export const OnChildRemoved = onChildRemoved;
export const StartAfter = startAfter;

// storage
export const GetStorage = getStorage;
export const RefStorage = refStorage;
export const UploadBytesResumable = uploadBytesResumable;
export const GetDownloadURL = getDownloadURL;
export const DeleteObject = deleteObject;
export const Messaging = getMessaging(app);
export const OnBackgroundMessage = onBackgroundMessage;
// notificaciones
// const messaging = getMessaging(firebaseApp);

// export const fechaHora = getDatabase(app);
//   funciones_fb.useFunctionsEmulator("http://localhost:5001");
/* export const funcionesFb = firebase.functions();
export const auth = firebase.auth();
export const db = firebase.database();
export const authPersistencia = firebase.auth.Auth.Persistence.LOCAL;
export const storage = firebase.storage();
export const constStorage = firebase.storage.TaskState;
export const fechaHora = firebase;
*/
