import * as React from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { UseUserState } from 'context/UserContext';

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
    { title: 'The Shawshank Redemption', year: 1994 },
    { title: 'The Godfather', year: 1972 },
    { title: 'The Godfather: Part II', year: 1974 },
    { title: 'The Dark Knight', year: 2008 }
];

export default function FiltroBot({ value, setVal }) {
    const { datos, listaBotRedes } = UseUserState();

    return (
        <Stack spacing={3}>
            <Autocomplete
                multiple
                value={value}
                id="tags-outlined"
                options={listaBotRedes}
                getOptionLabel={(option) => option.title}
                filterSelectedOptions
                renderInput={(params) => <TextField {...params} placeholder="Bot" />}
                onChange={(event, newValue) => setVal('bot', newValue)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
            />
        </Stack>
    );
}
