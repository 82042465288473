import { auth, OnAuthStateChanged, Basedatos, OnValue, Ref } from './setup';
import { db } from './basedatos';
import { MLog } from 'services/FuncionesDev';

export function watchUserChanges(callback) {
    const unsub = OnAuthStateChanged(auth, (user) => {
        if (user && !user.isAnonymous) {
            callback({
                id: user.uid,
                email: user.email,
                displayName: user.displayName,
                token: '',
                usuario: user
            });
        } else {
            callback(null);
        }
    });

    return unsub;
}

export function watchDatosUSer(uid, callback) {
    const ultimaConexion = `usuarios/${uid}/configuraciones/ultima_conexion`;
    const conectado = `conectado_chat/${uid}/en_linea`;

    db.read.on(conectado, (snapshot) => {
        const conect = snapshot.val();
        // const conect = false;
        db.read.once(`usuarios/${uid}`, (snap) => {
            const datos = snap.val();
            MLog.print('datosUID', datos.id_empresa);
            if (conect) {
                const datosNo = {
                    admin: false,
                    en_linea: true,
                    permisos: { paginas: ['otra_sesion'] },
                    configuraciones: ''
                };
                callback(datosNo);
            } else {
                db.onDisconnect(conectado, false).catch((err) => {
                    if (err) {
                        console.error('could not establish onDisconnect event', err);
                    }
                });
                db.onDisconnect(ultimaConexion, db.timestamp);

                // abandonar sesion
                const idFirebase = db.push(`/log_usuarios/${datos.id_empresa}/${uid}`, {
                    fecha: db.timestamp,
                    nombre: 'abrir sesión',
                    id_accion: 3,
                    fecha_fin: '',
                    id_accion_fin: '',
                    nombre_fin: ''
                });
                db.onDisconnectUpdate(`/log_usuarios/${datos.id_empresa}/${uid}/${idFirebase}`, {
                    fecha_fin: db.timestamp,
                    id_accion_fin: 4,
                    nombre_fin: 'abandonar sesión'
                });
                // db.set(conectado, true);
                localStorage.setItem('theme', 'default');
                localStorage.setItem('userData', JSON.stringify(datos));
                // localStorage.setItem('id_firebase', idFirebase);
                // db.set(conectado, true);

                db.read.once(`roles/${datos.rol}`, (userRol) => {
                    MLog.print('datos', datos);
                    const permisos = userRol.val();
                    callback({ ...datos, permisos });
                });
            }
        });
    });
}

export function WatchThema(uid, callback) {
    const URLdomain = document.domain;
    db.read.oncequery(db.query(db.ref('/tema_asignado'), db.orderByChild('domain'), db.equalTo(URLdomain)), (datos) => {
        if (datos.val() === null) {
            callback(null);
        } else {
            const temaActual = Object.keys(datos.val());
            datos.forEach((item) => {
                db.read.once(`tema/${datos.val()[temaActual].tema}`, (snapshot) => {
                    callback(snapshot.val().tema);
                });
            });
        }
    });
}

export function WatchPreferencias(uid, callback) {
    const otraS = `conectado_chat/${uid}/en_linea`;
    const conection = `usuarios/${uid}/configuraciones/ChatCenter`;
    MLog.print(`usuarios/${uid}/configuraciones/ChatCenter`);
    db.read.on(otraS, (snap) => {
        if (snap.val()) {
            const datosNo = {
                admin: false,
                en_linea: true,
                permisos: { paginas: ['otra_sesion'] },
                configuraciones: ''
            };
            callback(datosNo);
        } else {
            db.read.on(conection, (snapshot) => {
                const arreglo = snapshot.val();
                MLog.print('nuevo valor. usuario', arreglo);
                /* obtener informacion del usuario */
                db.read.once(`usuarios/${uid}`, (info) => {
                    const datos = info.val();
                    db.read.once(`roles/${datos.rol}`, (userRol) => {
                        const permisos = userRol.val();
                        callback({ ...datos, permisos });
                    });
                });
            });
        }
    });
}

export function WatchEtiquetas(uid, callback) {
    const conection = `usuarios/${uid}/configuraciones/chatcenter`;
    db.read.on(conection, (snapshot) => {
        const arreglo = snapshot.val();
        /* obtener informacion del usuario */
        db.read.once(`usuarios/${uid}`, (info) => {
            const datos = info.val();
            db.read.once(`roles/${datos.rol}`, (userRol) => {
                const permisos = userRol.val();
                callback({ ...datos, permisos });
            });
        });
    });
}
