import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
// project import
import useScriptRef from 'hooks/useScriptRef';
import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { OutlinedInput, Box, FormControl, InputLabel, FormHelperText, Stack, Typography, Grid } from '@mui/material';
import FiltroBot from './Preferencias/FiltroBot';
import FiltroRed from './Preferencias/FiltroRed';
import { UseUserDispatch, Logaout, UseUserState, MostrarPreferencias, CambiarPreferencias, SetBandejaActual } from 'context/UserContext';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

export default function Preferencias() {
    const theme = useTheme();
    const { preferencias, datos, user } = UseUserState();
    const userDispatch = UseUserDispatch();
    const scriptedRef = useScriptRef();
    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
        MostrarPreferencias(userDispatch, false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };

    return (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={preferencias}>
            <MainCard
                sx={{
                    maxWidth: { xs: 400, lg: 475 },
                    margin: { xs: 2.5, md: 3 },
                    '& > *': {
                        flexGrow: 1,
                        flexBasis: '50%'
                    }
                }}
                content={false}
            >
                <Box sx={{ p: { xs: 2, sm: 3, xl: 5 } }}>
                    <Grid container direction="column" justifyContent="center" spacing={2}>
                        <Grid item xs={12} container alignItems="center" justifyContent="center">
                            <Box sx={{ mb: 2 }}>
                                <Typography variant="subtitle1">
                                    Por favor seleccione los bots y redes sociales quiere ver en el chatcenter
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Formik
                        initialValues={{
                            bot: datos.configuraciones.ChatCenter === undefined ? [] : datos.configuraciones.ChatCenter.bots,
                            red: datos.configuraciones.ChatCenter === undefined ? [] : datos.configuraciones.ChatCenter.redes,
                            submit: null
                        }}
                        validationSchema={Yup.object().shape({
                            bot: Yup.array().min(1, 'Seleccione un bot').required(),
                            red: Yup.array().min(1, 'Seleccione una red').required()
                        })}
                        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                            try {
                                console.log('se inicio sesion', values);
                                CambiarPreferencias(userDispatch, values.red, values.bot, user, datos, MostrarPreferencias);
                                // SetBandejaActual(userDispatch, { title: 'chatcenter', id: 0 });
                            } catch (err) {
                                console.error(err);
                                if (scriptedRef.current) {
                                    setStatus({ success: false });
                                    setErrors({ submit: err.message });
                                    setSubmitting(false);
                                }
                            }
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <FormControl fullWidth error={Boolean(touched.bot && errors.bot)} sx={{ ...theme.typography.customInput }}>
                                    <FiltroBot value={values.bot} setVal={setFieldValue} />
                                    {touched.bot && errors.bot && (
                                        <FormHelperText error id="standard-weight-helper-text-email-login">
                                            {errors.bot}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl fullWidth error={Boolean(touched.red && errors.red)} sx={{ ...theme.typography.customInput }}>
                                    <FiltroRed value={values.red} setVal={setFieldValue} />
                                    {touched.red && errors.red && (
                                        <FormHelperText error id="standard-weight-helper-text-email-login">
                                            {errors.red}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                {errors.submit && (
                                    <Box sx={{ mt: 3 }}>
                                        <FormHelperText error>{errors.submit}</FormHelperText>
                                    </Box>
                                )}
                                {/* type="submit" */}
                                <Box sx={{ mt: 2 }}>
                                    <AnimateButton>
                                        <Button
                                            disableElevation
                                            disabled={isSubmitting}
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                        >
                                            Guardar
                                        </Button>
                                    </AnimateButton>
                                    <AnimateButton>
                                        <Button
                                            disableElevation
                                            fullWidth
                                            size="large"
                                            onClick={handleClose}
                                            variant="contained"
                                            color="secondary"
                                        >
                                            Cancelar
                                        </Button>
                                    </AnimateButton>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </MainCard>
        </Backdrop>
    );
}
