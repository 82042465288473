import { useRoutes, Navigate, Outlet } from 'react-router-dom';
// user context
import { UseUserState } from 'context/UserContext';

export default function RequireAuth() {
    const { isAuthenticated } = UseUserState();

    /* if (!isAuthenticated) {
        console.log('entro aqui!! id autenticate');
        return <Navigate to="/pages/login/login3" />;
    }
    */

    return (
        <>
            {/* `esta autenticado ${isAuthenticated}` */}
            {isAuthenticated ? <Outlet /> : <Navigate to="/login" />}
        </>
    );
}
