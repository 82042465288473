import React, { useEffect } from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { UseUserState } from 'context/UserContext';
import NuevaEtiqueta from './NuevaEtiqueta';
import { MLog } from 'services/FuncionesDev';

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
    { title: 'The Shawshank Redemption', year: 1994 },
    { title: 'The Godfather', year: 1972 },
    { title: 'The Godfather: Part II', year: 1974 },
    { title: 'The Dark Knight', year: 2008 }
];
const filter = createFilterOptions();

export default function FiltroEtiquetas({ value, setVal }) {
    const { datos, listaEtiqetas, etiquetasUserID } = UseUserState();
    const [open, toggleOpen] = React.useState(false);
    const [nuevoValor, setNuevoValor] = React.useState({ nombre: '', color: '' });

    const [etiquetas, setEtiquetas] = React.useState([]);

    useEffect(() => {
        const arreglos = [];
        Object.entries(listaEtiqetas).map((item, index) => arreglos.push(item[1]));
        setEtiquetas(arreglos);
    }, [listaEtiqetas]);

    useEffect(() => {
        MLog.print('inicial', etiquetasUserID);
        if (
            etiquetasUserID !== undefined &&
            etiquetasUserID !== null &&
            etiquetasUserID !== '' &&
            etiquetasUserID.etiquetas !== undefined
        ) {
            MLog.print('etiquetas', etiquetasUserID.etiquetas);
            const etiquetas = [];
            Object.entries(etiquetasUserID.etiquetas).map((item, index) => etiquetas.push(listaEtiqetas[item[0]]));
            setVal('etiquetas', etiquetas);
        }
    }, [etiquetasUserID]);

    return (
        <>
            <Stack spacing={3}>
                <Autocomplete
                    multiple
                    value={value}
                    id="tags-outlined"
                    options={etiquetas}
                    getOptionLabel={(option) => option.nombre}
                    filterSelectedOptions
                    renderInput={(params) => <TextField {...params} placeholder="Etiqueta" />}
                    onChange={(event, newValue) => {
                        MLog.print('newValue', newValue);
                        if (newValue.length > 0 && newValue[newValue.length - 1].inputValue) {
                            setNuevoValor({ nombre: newValue[newValue.length - 1].inputValue, color: '' });
                            toggleOpen(true);
                            MLog.print('nuevo valor');
                        } else {
                            setVal('etiquetas', newValue);
                        }
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                            <Chip
                                label={
                                    <span
                                        style={{
                                            color: option.color,
                                            WebkitFilter: 'invert(100%)',
                                            filter: 'invert(100%)'
                                        }}
                                    >
                                        {option.nombre}
                                    </span>
                                }
                                style={{ backgroundColor: option.color }}
                                {...getTagProps({ index })}
                            />
                        ))
                    }
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        if (params.inputValue !== '') {
                            filtered.push({
                                inputValue: params.inputValue,
                                nombre: `Agregar "${params.inputValue}"`
                            });
                        }
                        return filtered;
                    }}
                />
            </Stack>
            <NuevaEtiqueta
                open={open}
                toggleOpen={toggleOpen}
                listaValor={value}
                setListaValor={setVal}
                dialogValue={nuevoValor}
                setDialogValue={setNuevoValor}
            />
        </>
    );
}
