import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { UseUserState, UseUserDispatch, ErrorNotification } from 'context/UserContext';

export default function DialogNotification() {
    const [open, setOpen] = React.useState(false);
    const { datos, errorNotificacion } = UseUserState();
    const userDispatch = UseUserDispatch();

    const handleClose = () => {
        // setOpen(false);
        ErrorNotification(userDispatch, { open: false, mensaje: '', titulo: '' });
    };

    return (
        <>
            <Dialog
                open={errorNotificacion === undefined ? false : errorNotificacion.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{errorNotificacion === undefined ? '' : errorNotificacion.titulo}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {errorNotificacion === undefined ? '' : errorNotificacion.mensaje}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
