import React, { useEffect } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import { UseUserState, UseUserDispatch, OpenAtributos, ContextGuardarAtributo } from 'context/UserContext';
import TextField from '@mui/material/TextField';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import StarBorder from '@mui/icons-material/StarBorder';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import { useTheme } from '@mui/material/styles';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';

export default function Atributo({ valor, tipo }) {
    const { datos, usuarioActual } = UseUserState();
    const [open, setOpen] = React.useState(false);
    const [nombreAtributo, setNombreAtributo] = React.useState(tipo === 1 ? valor[0] : '');
    const [valorAtributo, setValorAtributo] = React.useState(tipo === 1 ? valor[1] : '');
    const [error, setError] = React.useState('');
    const [cargando, setCargando] = React.useState(false);

    const theme = useTheme();
    const handleClick = () => {
        setOpen(!open);
    };

    const GetIcono = () => {
        if (tipo === 0) {
            return <AddIcon />;
        }
        return <ExpandMore />;
    };

    const ReiniciarVariables = () => {
        setNombreAtributo('');
        setValorAtributo('');
        setError('');
        setOpen(false);
        setCargando(false);
    };

    const SaveAtributo = (e) => {
        setCargando(true);
        console.log('save atributio', tipo);
        if (tipo === 1 || (tipo === 0 && (nombreAtributo.length > 0 || valorAtributo.length > 0))) {
            // buscar si ya existe el atributo
            console.log('save atributio 2');
            if (
                nombreAtributo === 'chatcenter' ||
                nombreAtributo === 'fecha_chatcenter' ||
                nombreAtributo === 'fb_picture' ||
                nombreAtributo === 'bloque' ||
                nombreAtributo === 'posicion' ||
                nombreAtributo === 'ultima_fecha' ||
                nombreAtributo === 'log_chatcenter' ||
                nombreAtributo === 'asesor_id' ||
                nombreAtributo === 'asesor_name' ||
                nombreAtributo === 'ultima_fecha' ||
                nombreAtributo === 'ig_picture' ||
                nombreAtributo === 'intentos' ||
                nombreAtributo === 'fecha_ingreso' ||
                nombreAtributo === 'fb_username' ||
                nombreAtributo === 'wb_username' ||
                nombreAtributo === 'ig_username' ||
                nombreAtributo === 'tg_username' ||
                nombreAtributo === 'tg_nombre' ||
                nombreAtributo === 'ws_username'
            ) {
                setError('No se puede crear un atributo con este nombre');
                setCargando(false);
            } else if (tipo === 0 && nombreAtributo in usuarioActual.dato.atributos) {
                setError('El nombre del atributo ya existe');
                setCargando(false);
            } else {
                ContextGuardarAtributo(nombreAtributo, valorAtributo, datos, usuarioActual, ReiniciarVariables);
                console.log('save atributio3');
            }
        }
    };

    const DeleteAtributo = (e) => {
        setCargando(true);
        ContextGuardarAtributo(nombreAtributo, null, datos, usuarioActual, ReiniciarVariables);
    };

    return (
        <>
            <ListItemButton onClick={handleClick} sx={{ backgroundColor: tipo === 0 ? theme.palette.background.fondo : null }}>
                {tipo === 0 && (
                    <ListItemIcon>
                        <AddToPhotosIcon />
                    </ListItemIcon>
                )}
                <ListItemText primary={valor[0]} secondary={valor[1].toString()} />
                {open ? <ExpandLess /> : GetIcono()}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List
                    component="div"
                    disablePadding
                    sx={{ backgroundColor: tipo === 0 ? theme.palette.background.fondo : null, width: 'auto' }}
                >
                    <ListItem alignItems="flex-start">
                        <ListItemText
                            primary={
                                <Grid container spacing={1} direction="column" justifyContent="flex-start" alignItems="center">
                                    {tipo === 0 && (
                                        <Grid item xs={12}>
                                            <TextField
                                                id="outlined-nombre"
                                                label="Nombre"
                                                variant="outlined"
                                                value={nombreAtributo}
                                                onChange={(e) => setNombreAtributo(e.target.value)}
                                                error={
                                                    error.length > 0 ||
                                                    (nombreAtributo.length > 0 && !/^[a-zA-Z0-9_-]{1,30}$/.test(nombreAtributo))
                                                }
                                                helperText={
                                                    nombreAtributo.length > 0 && !/^[a-zA-Z0-9_-]{1,30}$/.test(nombreAtributo)
                                                        ? 'El nombre no puede tener espacios y debe ser menor a 30 caracteres'
                                                        : error
                                                }
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <TextField
                                            id="outlined-valor"
                                            label="Valor"
                                            variant="outlined"
                                            value={valorAtributo}
                                            onChange={(e) => setValorAtributo(e.target.value)}
                                        />
                                    </Grid>
                                    {cargando ? (
                                        <Grid item xs={12}>
                                            <CircularProgress />
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12}>
                                            <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
                                                {tipo === 1 && (
                                                    <Grid item xs={6}>
                                                        <IconButton onClick={DeleteAtributo}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Grid>
                                                )}
                                                <Grid item xs={6}>
                                                    <IconButton
                                                        disabled={nombreAtributo.length === 0 || valorAtributo.length === 0}
                                                        onClick={SaveAtributo}
                                                    >
                                                        <SaveIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            }
                        />
                    </ListItem>
                </List>
            </Collapse>
        </>
    );
}
