import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// user context
import { ThemeProvider as CustomTheme } from './context/ThemeContext';

// routing
import Routes from 'routes';
// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import Root from 'Root';
import RootTheme from 'RootTheme';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);

    return (
        <Root>
            <CustomTheme>
                <RootTheme>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={themes(customization)}>
                            <CssBaseline />
                            <NavigationScroll>
                                <Routes />
                            </NavigationScroll>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </RootTheme>
            </CustomTheme>
        </Root>
    );
};

export default App;
