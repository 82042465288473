export const mensajesChat = 'https://bots.automatadg.com/servicio/conexion';
export const resetVar = 'https://bots.automatadg.com/servicio/restart_var';

export const mensajeWhatsapp = 'https://bots.automatadg.com/whatsapp/conexion';
export const mensajeFacebook = 'https://bots.automatadg.com/conexion/webhook';
export const mensajeTelegram = 'https://bots.automatadg.com/telegram/conexion';
export const mensajeInstagram = 'https://bots.automatadg.com/instagram/conexion';
export const mensajeWebchat = 'https://bots.automatadg.com/webchat/webhook';

/*
export const mensajes_chat='https://dev.automatadg.com/servicio/conexion';

export const mensaje_whatsapp='https://dev.automatadg.com/whatsapp/conexion';
export const mensaje_facebook='https://dev.automatadg.com/conexion/webhook';
export const mensaje_telegram="https://dev.automatadg.com/telegram/conexion"
*/
