import { db } from 'services/firebase';
import { mensajeWhatsapp, mensajeFacebook, mensajeTelegram, mensajeInstagram, mensajeWebchat, mensajesChat } from 'services/automata';
import { MLog } from 'services/FuncionesDev';

export const infoUser = (info, dato) => {
    switch (info.red) {
        case 'facebook':
            return { user: dato.atributos.fb_username };
        case 'whatsapp':
            return { user: dato.atributos.ws_username };
        case 'telegram':
            return { user: dato.atributos.tg_nombre };
        case 'instagram':
            return { user: dato.atributos.ig_username };
        default:
            return { user: dato.atributos.wb_username };
    }
};

export function LogAsesor(user, datos, usuarioActual, bandeja) {
    MLog.print('logAsesor', usuarioActual);
    return new Promise((fulfill, reject) => {
        MLog.print('logAsesor 0');
        if (usuarioActual === '') {
            fulfill();
        } else if (usuarioActual.dato.atributos.log_chatcenter === undefined) {
            // si no tiene asesor asignado
            // se le asiga el usuario actual como asesor
            const obj = {
                estado: true,
                fecha_ini: db.timestamp,
                fecha_usuario: db.timestamp,
                id: usuarioActual.info.id,
                usuario: user.id,
                nombre: datos.nombre,
                bandeja
            };
            MLog.print('logAsesor 2');
            const refPush = db.push(`log_chatcenter/${datos.id_empresa}/${usuarioActual.info.red}/${usuarioActual.info.idbot}`, obj);
            db.update(
                `cliente_chat/${datos.id_empresa}/${usuarioActual.info.red}/${usuarioActual.info.idbot}/${usuarioActual.info.id}/atributos`,
                {
                    log_chatcenter: refPush,
                    asesor_id: user.id,
                    asesor_name: datos.nombre
                }
            );
            MLog.print('logAsesor 3');
            fulfill();
            // Si el asesor del usuario actual es diferente al asesor asignado
            // el asesor se mueve al historial y el usuario se coloca como asesor
        } else if (usuarioActual.dato.atributos.log_chatcenter !== undefined && usuarioActual.dato.atributos.asesor_id !== user.id) {
            MLog.print(
                'rurta1',
                `log_chatcenter/${datos.id_empresa}/${usuarioActual.info.red}/${usuarioActual.info.idbot}/${usuarioActual.dato.atributos.log_chatcenter}`
            );
            MLog.print(
                'rurta1',
                `log_chatcenter/${datos.id_empresa}/${usuarioActual.info.red}/${usuarioActual.info.idbot}/${usuarioActual.dato.atributos.log_chatcenter}`
            );
            MLog.print(
                'rurta1',
                `cliente_chat/${datos.id_empresa}/${usuarioActual.info.red}/${usuarioActual.info.idbot}/${usuarioActual.info.id}/atributos/asesor_id`
            );
            MLog.print(
                'rurta1',
                `cliente_chat/${datos.id_empresa}/${usuarioActual.info.red}/${usuarioActual.info.idbot}/${usuarioActual.info.id}/atributos/asesor_name`
            );

            db.read.once(
                `log_chatcenter/${datos.id_empresa}/${usuarioActual.info.red}/${usuarioActual.info.idbot}/${usuarioActual.dato.atributos.log_chatcenter}`,
                (snap) => {
                    const valores = snap.val();
                    let historial = [];

                    if (valores === null) {
                        fulfill();
                    } else {
                        if ('historial' in valores) {
                            historial = valores.historial;
                        }
                        historial.push({
                            fecha_ini: valores.fecha_usuario === undefined ? valores.fecha_ini : valores.fecha_usuario,
                            fecha_fin: db.timestamp,
                            usuario: valores.usuario,
                            nombre: valores.nombre,
                            bandeja: valores.bandeja
                        });
                        db.set(
                            `log_chatcenter/${datos.id_empresa}/${usuarioActual.info.red}/${usuarioActual.info.idbot}/${usuarioActual.dato.atributos.log_chatcenter}`,
                            {
                                estado: true,
                                fecha_ini: valores.fecha_ini,
                                id: valores.id,
                                historial,
                                fecha_usuario: db.timestamp,
                                usuario: user.id,
                                nombre: datos.nombre,
                                bandeja,
                                historialBandejas: valores.historialBandejas === undefined ? null : valores.historialBandejas
                            }
                        );
                        db.set(
                            `cliente_chat/${datos.id_empresa}/${usuarioActual.info.red}/${usuarioActual.info.idbot}/${usuarioActual.info.id}/atributos/asesor_id`,
                            user.id
                        );
                        db.set(
                            `cliente_chat/${datos.id_empresa}/${usuarioActual.info.red}/${usuarioActual.info.idbot}/${usuarioActual.info.id}/atributos/asesor_name`,
                            datos.nombre
                        );
                    }
                }
            );
        }
        fulfill();
    });
}

export function SalirChatcenterLogAsesor(user, datos, usuarioActual) {
    return new Promise((fulfill, reject) => {
        MLog.print('usuarioActual en log asesor', usuarioActual);
        if (usuarioActual === '' || usuarioActual.dato.atributos.log_chatcenter === undefined) {
            MLog.print('paso2');
            fulfill();
            return 0;
        }
        MLog.print('paso22', usuarioActual);
        db.read.once(
            `log_chatcenter/${datos.id_empresa}/${usuarioActual.info.red}/${usuarioActual.info.idbot}/${usuarioActual.dato.atributos.log_chatcenter}`,
            (snap) => {
                const valores = snap.val();
                let historial = [];

                if (valores === null) {
                    fulfill();
                } else {
                    if ('historial' in valores) {
                        historial = valores.historial;
                    }

                    // se agrego el ultimo usuario al historial
                    historial.push({
                        fecha_ini: valores.fecha_usuario === undefined ? valores.fecha_ini : valores.fecha_usuario,
                        fecha_fin: db.timestamp,
                        usuario: valores.usuario,
                        nombre: valores.nombre
                    });
                    // agregar al usuario que esta sacandolo del chatcenter
                    /* historial.push({
                        fecha_ini: db.timestamp,
                        fecha_fin: db.timestamp,
                        usuario: user.id,
                        nombre: datos.nombre
                    }); */
                    MLog.print('paso 44 log', historial);
                    MLog.print('paso 44 log datos', {
                        estado: false,
                        usuario: null,
                        nombre: null,
                        fecha_usuario: null,
                        fecha_fin: db.timestamp,
                        historial
                    });

                    MLog.print(
                        'paso4',
                        `log_chatcenter/${datos.id_empresa}/${usuarioActual.info.red}/${usuarioActual.info.idbot}/${usuarioActual.dato.atributos.log_chatcenter}`
                    );

                    MLog.print(
                        'paso45',
                        `cliente_chat/${datos.id_empresa}/${usuarioActual.info.red}/${usuarioActual.info.idbot}/${usuarioActual.info.id}/atributos/`
                    );

                    db.update(
                        `log_chatcenter/${datos.id_empresa}/${usuarioActual.info.red}/${usuarioActual.info.idbot}/${usuarioActual.dato.atributos.log_chatcenter}`,
                        {
                            estado: false,
                            usuario: null,
                            nombre: null,
                            fecha_usuario: null,
                            fecha_fin: db.timestamp,
                            historial
                        }
                    );
                    MLog.print(
                        'paso5',
                        `cliente_chat/${datos.id_empresa}/${usuarioActual.info.red}/${usuarioActual.info.idbot}/${usuarioActual.info.id}/atributos/`
                    );
                    db.update(
                        `cliente_chat/${datos.id_empresa}/${usuarioActual.info.red}/${usuarioActual.info.idbot}/${usuarioActual.info.id}/atributos/`,
                        {
                            log_chatcenter: null,
                            fecha_chatcenter: null,
                            asesor_id: null,
                            asesor_name: null
                        }
                    );
                }
            }
        );
        fulfill();
        return 0;
    });
}

function simularMensaje(user, datos, usuarioActual) {
    MLog.print('usuario actual Simulando', usuarioActual);
    if (usuarioActual.info.red === 'facebook') {
        // metodo de envio de mensaje
        fetch(`${mensajeFacebook}?bot=${usuarioActual.info.idbot}&id=${datos.id_empresa}`, {
            method: 'POST',
            body: JSON.stringify({
                entry: [
                    {
                        messaging: [
                            {
                                message: {
                                    mid: datos.id_empresa,
                                    text: 'nextElement'
                                },
                                sender: {
                                    id: usuarioActual.info.id
                                }
                            }
                        ]
                    }
                ]
            })
        })
            .then((response) => response)
            .then((empleados) => {
                /*  DetenerAtenederUsuario(chatDispach);
                  setCargando(false);
                  ReiniciarVariables();
                  CerarSalirChat(chatDispach);
                */
                MLog.print('empleados', empleados);
            })
            .catch((error) => {
                /*
                  setError(true);
                  setCargando(false);
                */
                MLog.print('error', error);
            });
    } else if (usuarioActual.info.red === 'whatsapp') {
        MLog.print('eejemplo', {
            payload: {
                id: 'AUTOMATADIGITal-aVIBOTwhats2',
                payload: {
                    text: 'nextElement'
                },
                sender: {
                    country_code: '502',
                    dial_code: usuarioActual.info.idbot.substring(3),
                    name: usuarioActual.dato.atributos.ws_username,
                    phone: usuarioActual.info.idbot
                },
                source: usuarioActual.info.idbot,
                type: 'text'
            },
            timestamp: db.timestamp,
            type: 'message',
            version: 2
        });
        MLog.print('url', `${mensajeWhatsapp}?bot=${usuarioActual.info.idbot}&id=${datos.id_empresa}`);
        fetch(`${mensajeWhatsapp}?bot=${usuarioActual.info.idbot}&id=${datos.id_empresa}`, {
            method: 'POST',
            body: JSON.stringify({
                payload: {
                    id: 'AUTOMATADIGITal-aVIBOTwhats2',
                    payload: {
                        text: 'nextElement'
                    },
                    sender: {
                        country_code: '502',
                        dial_code: usuarioActual.info.idbot.substring(3),
                        name: usuarioActual.dato.atributos.ws_username,
                        phone: usuarioActual.info.idbot
                    },
                    source: usuarioActual.info.idbot,
                    type: 'text'
                },
                timestamp: db.timestamp,
                type: 'message',
                version: 2
            }) // data can be `string` or {object}!
        })
            .then((response) => response)
            .then((empleados) => {
                /* DetenerAtenederUsuario(chatDispach);
                setCargando(false);
                ReiniciarVariables();
                CerarSalirChat(chatDispach);
                */
                MLog.print('empleados');
            })
            .catch((error) => {
                /* setError(true);
                setCargando(false);
                */
                MLog.print('error', error);
            });
    } else if (usuarioActual.info.red === 'instagram') {
        fetch(`${mensajeInstagram}?bot=${usuarioActual.info.idbot}&id=${datos.id_empresa}`, {
            method: 'POST',
            body: JSON.stringify({
                entry: [
                    {
                        id: '97118105',
                        messaging: [
                            {
                                message: {
                                    mid: '97118105',
                                    text: 'nextElement'
                                },
                                recipient: {
                                    id: '97118105'
                                },
                                sender: {
                                    id: usuarioActual.info.id
                                },
                                timestamp: db.timestamp
                            }
                        ],
                        time: db.timestamp
                    }
                ],
                object: 'instagram'
            }) // data can be `string` or {object}!
        })
            .then((response) => response)
            .then((empleados) => {
                /*
                DetenerAtenederUsuario(chatDispach);
                setCargando(false);
                ReiniciarVariables();
                CerarSalirChat(chatDispach);
                */
                MLog.print('empleados', empleados);
            })
            .catch((error) => {
                /* setError(true);
                setCargando(false);
                */
                MLog.print('error', error);
            });
    } else if (usuarioActual.info.red === 'telegram') {
        const name = usuarioActual.dato.atributos.tg_nombre.split(' ');
        const firstname = name[0] != null ? name[0] : '';
        const lastname = name[1] != null ? name[1] : '';

        fetch(`${mensajeTelegram}?info=${usuarioActual.info.idbot},${datos.id_empresa}`, {
            method: 'POST',
            body: JSON.stringify({
                message: {
                    chat: {
                        first_name: firstname,
                        id: usuarioActual.info.id,
                        last_name: lastname,
                        username: usuarioActual.dato.atributos.tg_nombre
                    },
                    text: 'nextElement'
                }
            }) // data can be `string` or {object}!
        })
            .then((response) => response)
            .then((empleados) => {
                /*
                DetenerAtenederUsuario(chatDispach);
                setCargando(false);
                ReiniciarVariables();
                CerarSalirChat(chatDispach);
                */
                MLog.print('empleados', empleados);
            })
            .catch((error) => {
                MLog.print('error al enviar', error);
                // setCargando(false);
                // setError(true);
            });
    } else {
        // chatweb
        fetch(`${mensajeWebchat}?bot=${usuarioActual.info.idbot}&id=${datos.id_empresa}`, {
            method: 'POST',
            body: JSON.stringify({
                msg: [
                    {
                        id: 'AV1ADW3BCH4T',
                        messaging: [
                            {
                                message: {
                                    type: 'text',
                                    text: 'nextElement'
                                },
                                user: {
                                    id: usuarioActual.info.idbot
                                }
                            }
                        ],
                        date: db.timestamp
                    }
                ]
            })
        })
            .then((response) => response)
            .then((empleados) => {
                MLog.print('empleados', empleados);
                /*
                DetenerAtenederUsuario(chatDispach);
                setCargando(false);
                ReiniciarVariables();
                CerarSalirChat(chatDispach);
                */
            })
            .catch((error) => {
                MLog.print('error', error);
                /*
                setError(true);
                setCargando(false);
                */
            });
    }
}

export function AgregarChatcenter(
    user,
    datos,
    usuarioActual,
    dispach,
    setIndexUsuarioActual,
    setUsuarioActual,
    setBandejaActual,
    ModificarLoad
) {
    ModificarLoad(dispach, true);

    MLog.print('AgregarChatcenter datos', datos);
    MLog.print('AgregarChatcenter user', datos);
    MLog.print('usuario actuakl', usuarioActual);
    MLog.print(
        'cleinte chat ',
        `cliente_chat/${datos.id_empresa}/${usuarioActual.info.red}/${usuarioActual.info.idbot}/${usuarioActual.info.id}/atributos`
    );
    db.update(`cliente_chat/${datos.id_empresa}/${usuarioActual.info.red}/${usuarioActual.info.idbot}/${usuarioActual.info.id}/atributos`, {
        chatcenter: true,
        fecha_chatcenter: db.timestamp,
        bandeja: null,
        acceso_asesor: usuarioActual.dato.atributos.ultima_fecha,
        leido: true,
        msg_noleido: 0
    });
    // cambiar bandeja actual
    setBandejaActual(dispach, { title: 'chatcenter', id: 0 });
    // seleccionar usuario actual
    setIndexUsuarioActual(dispach, usuarioActual.id);
    // set usaurio actual
    setUsuarioActual(dispach, {
        id: usuarioActual.id,
        red: usuarioActual.info.red,
        dato: usuarioActual.dato,
        info: usuarioActual.info,
        userName: infoUser(usuarioActual.info, usuarioActual.dato).user
    });
    // agregar accion al log
    LogAsesor(user, datos, usuarioActual, 'chatcenter');
    // terminar el log de carga
    ModificarLoad(dispach, false);
}

// borrar variables
function DeleteAtributos(empresa, bot, red, userid) {
    const formData = new FormData();
    formData.append('empresa', empresa);
    formData.append('bot', bot);
    formData.append('red', red);
    formData.append('userid', userid);
    fetch('https://bots.automatadg.com/servicio/restart_var', {
        method: 'POST',
        body: formData
    })
        .then((response) => {
            MLog.print('respuesta', response);
        })
        .catch((error) => {
            MLog.print('error al borrar las variables');
        });
}
/** *
 *Saca a un usuario dado del chatcenter
 *@author hugocc@automatadg.com
 *@version 1
 *@param {obj} user usuario actual
 *@param {onj} informacion desde la base de datos del usuario actuales
 *@param {obj} usuarioActual usuario al cual se desea sacar del ChatCenter
 *@param {obj} tipo es el i del tipo de accion al salir del ChatCenter
 *1: Mover al inicio del flujo, sin simular mensaje ni reiniciar variables
 *2: Continuar con el lfujo del bot, sin simular mensaje ni reiniciar variables
 *11: Mover al inicio del flujo simulando mensaje y no reiniciar variables
 *12: Mover al inicio del flujo sin simular mensaje y reiniciando variables
 *112: Mover al inicio del flujo simulando mensaje y reiniciando variables
 *21 : Continuar con el flujo del bot simulando mensajes y no reiniciando variables
 * */
export function SalirChatcenter(user, datos, usuarioActual, tipo, usuarioActualContext, SetUsuarioActual, SetIndexUsuarioActual, dispach) {
    MLog.print('SalirChatcenter', usuarioActual);
    // eliminar de la lista de usuarios en bandejaActual
    if (usuarioActual.dato.atributos.bandeja !== undefined) {
        db.set(
            `bandeja_usuario/${datos.id_empresa}/${usuarioActual.info.red}/${usuarioActual.info.idbot}/${usuarioActual.dato.atributos.bandeja}/${usuarioActual.info.id}/`,
            null
        );
    }
    db.update(`cliente_chat/${datos.id_empresa}/${usuarioActual.info.red}/${usuarioActual.info.idbot}/${usuarioActual.info.id}/atributos`, {
        chatcenter: false,
        fecha_chatcenter: null,
        bandeja: null,
        acceso_asesor: null,
        leido: null,
        msg_noleido: null
    });
    // si hay q mover al inicio
    if (tipo === 1 || tipo === 11 || tipo === 12 || tipo === 112) {
        db.update(
            `cliente_chat/${datos.id_empresa}/${usuarioActual.info.red}/${usuarioActual.info.idbot}/${usuarioActual.info.id}/user_posicion`,
            {
                bloque: 'default',
                posicion: 0
            }
        );
    }
    // si hay que simular el mensajes
    if (tipo === 11 || tipo === 112 || tipo === 21) {
        simularMensaje(user, datos, usuarioActual);
    }
    // si hay que borrar las variables
    if (tipo === 12 || tipo === 112) {
        DeleteAtributos(datos.id_empresa, usuarioActual.info.idbot, usuarioActual.info.red, usuarioActual.info.id);
    }
    SalirChatcenterLogAsesor(user, datos, usuarioActual);
    MLog.print('usuario a sacar del chatcenter', usuarioActual);
    MLog.print('usuarioContext del chatcenter', usuarioActualContext);
    // si el usuario es el actual
    if (usuarioActualContext.id === usuarioActual.id) {
        MLog.print('si entro en quitar atual');
        // cambiar bandeja actual
        SetUsuarioActual(dispach, '');
        // seleccionar usuario actual
        SetIndexUsuarioActual(dispach, 0);
    }
}

export function EnviarMensaje(
    usuarioActual,
    mensajeEnviar,
    datos,
    user,
    tipo,
    nombre,
    tamano,
    propietario,
    bandeja,
    error,
    enviando,
    dispach,
    setIndexUsuarioActual,
    setUsuarioActual,
    setBandejaActual,
    ModificarLoad,
    ReiniciarVariables
) {
    if (usuarioActual !== '' && mensajeEnviar !== '') {
        // si el usuario actual no esta en ChatCenter
        if (!('log_chatcenter' in usuarioActual.dato.atributos)) {
            AgregarChatcenter(
                user,
                datos,
                usuarioActual,
                dispach,
                setIndexUsuarioActual,
                setUsuarioActual,
                setBandejaActual,
                ModificarLoad
            );
        } else {
            LogAsesor(user, datos, usuarioActual, bandeja.title);
        }
        error(false);
        enviando(true);
        const formData = new FormData();
        formData.append('empresa', datos.id_empresa);
        formData.append('userid', usuarioActual.info.id);
        formData.append('bot', usuarioActual.info.idbot);
        formData.append('red', usuarioActual.info.red);
        formData.append('mensaje', mensajeEnviar);
        formData.append('tipo', tipo);
        formData.append('user_cc', datos.nombre);
        formData.append('iduser_cc', user.id);
        formData.append('nombre', nombre);
        formData.append('tamano', tamano);
        formData.append('propietario', propietario);

        fetch(mensajesChat, {
            method: 'POST',
            body: formData
        })
            .then((response) => response)
            .then((empleados) => {
                MLog.print('resul;t', empleados);
                // agregar el log si se cambio de usuario
                // LogAsesor(user, datos, usuarioActual, bandeja);
                enviando(false);
                ReiniciarVariables();
            })
            .catch((error) => {
                MLog.print('error enviado', error);
                enviando(false);
                error(true);
                ReiniciarVariables();
            });
    }
}

/* Cargar multimedia */
export function CargarMultimedia(dispatch, datos) {
    db.read.once(`multimedia/${datos.id_empresa}/imagen`, (snap) => {
        const multimedia = [];
        snap.forEach((item, i) => {
            const info = item.val();
            info.key = item.key;
            multimedia.push(info);
        });
        dispatch({ type: 'CARGAR_ARCHIVOS', tipo: 0, archivos: multimedia });
    });

    db.read.once(`multimedia/${datos.id_empresa}/archivos`, (snap) => {
        const multimedia = [];
        snap.forEach((item, i) => {
            const info = item.val();
            info.key = item.key;
            multimedia.push(info);
        });
        dispatch({ type: 'CARGAR_ARCHIVOS', tipo: 1, archivos: multimedia });
    });

    db.read.once(`multimedia/${datos.id_empresa}/audios`, (snap) => {
        const multimedia = [];
        snap.forEach((item, i) => {
            const info = item.val();
            info.key = item.key;
            multimedia.push(info);
        });
        dispatch({ type: 'CARGAR_ARCHIVOS', tipo: 2, archivos: multimedia });
    });

    db.read.once(`multimedia/${datos.id_empresa}/videos`, (snap) => {
        const multimedia = [];
        snap.forEach((item, i) => {
            const info = item.val();
            info.key = item.key;
            multimedia.push(info);
        });
        dispatch({ type: 'CARGAR_ARCHIVOS', tipo: 3, archivos: multimedia });
    });
}

export function GuardarAtributos(nombre, valor, datos, usuarioActual, ReiniciarVariables) {
    db.set(
        `cliente_chat/${datos.id_empresa}/${usuarioActual.info.red}/${usuarioActual.info.idbot}/${usuarioActual.info.id}/atributos/${nombre}`,
        valor
    );
    ReiniciarVariables();
}
