import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import ListItemButton from '@mui/material/ListItemButton';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import ArticleIcon from '@mui/icons-material/Article';
import MenuItem from '@mui/material/MenuItem';
import { UseUserState, UseUserDispatch, OpenAtributos } from 'context/UserContext';

import Atributo from './Atributo';

export default function Atributos() {
    const [open, setOpen] = React.useState(false);
    const userDispatch = UseUserDispatch();
    const { showAtributos, usuarioActual } = UseUserState();

    const [listaAtributos, setListaAtributos] = React.useState([]);

    const Evento = (e) => {
        console.log('evento');
    };

    const handleClick = () => {
        setOpen(!open);
    };

    const AtributosNoeditables = (nombre) => {
        if (
            nombre === 'chatcenter' ||
            nombre === 'fecha_chatcenter' ||
            nombre === 'fb_picture' ||
            nombre === 'bloque' ||
            nombre === 'posicion' ||
            nombre === 'ultima_fecha' ||
            nombre === 'log_chatcenter' ||
            nombre === 'asesor_id' ||
            nombre === 'asesor_name' ||
            nombre === 'ultima_fecha' ||
            nombre === 'ig_picture' ||
            nombre === 'intentos' ||
            nombre === 'fecha_ingreso' ||
            nombre === 'fb_username' ||
            nombre === 'wb_username' ||
            nombre === 'ig_username' ||
            nombre === 'tg_username' ||
            nombre === 'tg_nombre' ||
            nombre === 'ws_username'
        ) {
            return false;
        }
        return true;
    };

    return (
        <>
            <SwipeableDrawer anchor="right" open={showAtributos} onClose={(e) => OpenAtributos(userDispatch, false)} onOpen={Evento}>
                <Box sx={{ width: 500, minWiwidth: '30%', maxWiwidth: '80%' }} role="presentation">
                    <List>
                        <Atributo valor={['Nuevo Atributo', '']} tipo={0} />
                        <Divider />
                        {Object.entries(usuarioActual.dato.atributos).map(
                            (item, index) => AtributosNoeditables(item[0]) && <Atributo valor={item} tipo={1} key={`Atributo-${item[0]}`} />
                        )}
                    </List>
                </Box>
            </SwipeableDrawer>
        </>
    );
}
