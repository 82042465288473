import { db } from 'services/firebase';
import { infoUser } from './mensajes';
import { MLog } from 'services/FuncionesDev';

export function AgregarNotificacion(notificacion, state) {
    // buscar si existe el usuario
    // 202001-facebook-12-4513660931998452
    const id = `${state.datos.id_empresa}-${notificacion.red}-${notificacion.bot}-${notificacion.id}`;
    const item = state.tableHashUser.get(id);
    // const noti = notificacion.bandeja === undefined ? 0 : state.bandejasAsignadas.indexOf(notificacion.bandeja);

    if (
        item !== undefined &&
        ((notificacion.bandeja === 'general' &&
            (state.datos.permisos.bandejas.admin || state.bandejasAsignadas.indexOf('chatcenter') > -1)) ||
            (notificacion.bandeja !== 'general' && state.bandejasAsignadas.indexOf(notificacion.bandeja) > -1))
    ) {
        // tipo: 0 chatcenter
        // tipo: 1 bandejas
        state.notificacionesContext.push({
            red: item.info.red,
            user: item.info.id,
            bot: item.info.idbot,
            botNombre: item.info.bot,
            nombre: infoUser(item.info, item.dato),
            create: notificacion.create,
            notificacion: notificacion.key,
            texto: notificacion.texto,
            tipo: notificacion.bandeja === 'general' ? 0 : 1,
            id_operador: notificacion.id_operador,
            nombre_operador: notificacion.nombre_operador,
            id,
            bandeja: notificacion.bandeja === 'general' ? 'chatcenter' : notificacion.bandeja
        });
    }
}

export function AgregarAllNotifications(notificacions, state) {
    // buscar si existe el usuario
    // 202001-facebook-12-4513660931998452
    const nuevas = [];
    notificacions.forEach((notificacion, i) => {
        const id = `${state.datos.id_empresa}-${notificacion.red}-${notificacion.bot}-${notificacion.id}`;
        const item = state.tableHashUser.get(id);
        // const noti = notificacion.bandeja === undefined ? 0 : state.bandejasAsignadas.indexOf(notificacion.bandeja);

        if (
            item !== undefined &&
            ((notificacion.bandeja === 'general' &&
                (state.datos.permisos.bandejas.admin || state.bandejasAsignadas.indexOf('chatcenter') > -1)) ||
                (notificacion.bandeja !== 'general' && state.bandejasAsignadas.indexOf(notificacion.bandeja) > -1))
        ) {
            // tipo: 0 chatcenter
            // tipo: 1 bandejas
            nuevas.push({
                red: item.info.red,
                user: item.info.id,
                bot: item.info.idbot,
                botNombre: item.info.bot,
                nombre: infoUser(item.info, item.dato),
                create: notificacion.create,
                notificacion: notificacion.key,
                texto: notificacion.texto,
                tipo: notificacion.bandeja === 'general' ? 0 : 1,
                id_operador: notificacion.id_operador,
                nombre_operador: notificacion.nombre_operador,
                id,
                bandeja: notificacion.bandeja === 'general' ? 'chatcenter' : notificacion.bandeja
            });
        }
    });
    state.notificacionesContext = nuevas;
}

export function EliminarNotificacion(notificacion, state) {
    MLog.print('Eliminar notificacion');
    const id = `${state.datos.id_empresa}-${notificacion.red}-${notificacion.bot}-${notificacion.id}`;
    const index = state.notificacionesContext.findIndex((item) => item.id === id);
    if (index !== -1) {
        state.notificacionesContext.splice(index, 1);
    }
}

export function AceptarTodo(state) {
    const aEliminar = [];
    // pasar todas a visto
    state.notificacionesContext.forEach((item, i) => {
        MLog.print('aceptar', item);
        db.set(`notificacion_empresa/${state.datos.id_empresa}/visto/${item.notificacion}`, {
            bot: item.bot,
            create: item.create,
            id: item.user,
            red: item.red,
            texto: item.texto,
            fecha_atendido: db.timestamp,
            id_operador: item.id_operador,
            nombre_operador: item.nombre_operador
        });
        aEliminar.push(`notificacion_empresa/${state.datos.id_empresa}/creado/${item.notificacion}`);
    });
    MLog.print('aEliminar', aEliminar);
    // eliminar de creado
    aEliminar.forEach((item, i) => {
        MLog.print('a eliminar', item);
        db.set(item, null);
    });
}

export function MarcarComoVisto(state, index) {
    try {
        MLog.print('marcar como leido', index);
        const item = state.notificacionesContext[index];
        MLog.print('marcar como leido item', item);
        db.set(`notificacion_empresa/${state.datos.id_empresa}/visto/${item.notificacion}`, {
            bot: item.bot,
            create: item.create,
            id: item.user,
            red: item.red,
            texto: item.texto,
            fecha_atendido: db.timestamp,
            id_operador: item.id_operador,
            nombre_operador: item.nombre_operador
        });
        db.set(`notificacion_empresa/${state.datos.id_empresa}/creado/${item.notificacion}`, null);
        // borrar de la lista de bandejas si existe
        db.set(`bandeja_usuario/${state.datos.id_empresa}/${item.red}/${item.bot}/${item.bandeja}/${item.user}/`, null);
    } catch (e) {
        const error = e;
    }
}

function CopiarObjeto(objeto) {
    return objeto;
}

export function MostrarUsuario(state, index) {
    try {
        const item = state.notificacionesContext[index];
        const hashItem = state.tableHashUser.get(item.id);
        if (hashItem !== undefined) {
            // buscar si el usuario sigue en la bandeja de la notificacion
            if (
                (item.bandeja === 'chatcenter' && hashItem.dato.atributos.chatcenter === false) ||
                item.bandeja !== hashItem.dato.atributos.bandeja ||
                hashItem.dato.atributos.chatcenter === false
            ) {
                state.errorNotificacion = {
                    open: true,
                    mensaje: 'Error al mostrar el usuario, el usuario ya no se encuentra en esta bandeja o en el Chat Center',
                    titulo: 'Error'
                };
                MarcarComoVisto(state, index);
                return 0;
            }
            // buscar la bandeja
            MLog.print('mostrarUsuario', item.bandeja);
            if (state.bandejaActual.title !== item.bandeja) {
                // si la bandeja es ChatCenter
                if (item.bandeja === 'ChatCenter') {
                    state.bandejaActual = { title: 'chatcenter', id: 0 };
                } else {
                    // buscar el id de la bandeja
                    MLog.print('mostrarUsuario lista bandejas', state.bandejas);
                    const bandeja = state.bandejas.indexOf(item.bandeja);
                    MLog.print('mostrarUsuario bandeja index', bandeja);
                    if (bandeja === -1) {
                        state.errorNotificacion = {
                            open: true,
                            mensaje: 'Error al mostrar el usuario, bandeja no encontrada',
                            titulo: 'Error'
                        };
                        MarcarComoVisto(state, index);
                        return 0;
                    }
                    state.bandejaActual = { title: state.bandejas[bandeja], id: bandeja + 2 };
                    MLog.print('nueva bandeja actual', state.bandejaActual);
                }
            }
            // buscar si esta en la lista actual el usuarioa mostrar
            const mostrarActual = state.listaUsuariosActual.findIndex((valor) => valor.id === item.id);
            // si no esta se busca en lista usuarios
            if (mostrarActual === -1) {
                // se coloca en primera posicion de la lista de usuarios y en primra posicion de la lista actual
                state.listaUsuarios.unshift({ id: item.id, ultima_fecha: db.timestamp });
                state.listaUsuarios.splice(index, 1);
                // si hay para cargar mas se aumenta en 1 el ultimo
                if (state.cargarmas.cargarmas) {
                    state.cargarmas.ultimo += 1;
                }
            }
            // asignar como usuario actual al uuario de la notificacion
            state.usuarioActual = {
                id: item.id,
                red: hashItem.info.red,
                dato: hashItem.dato,
                info: hashItem.info,
                userName: infoUser(hashItem.info, hashItem.dato).user
            };
            state.indexListachat = item.id;
            MLog.print('usuario actual', {
                id: item.id,
                red: hashItem.info.red,
                dato: hashItem.dato,
                info: hashItem.info,
                userName: infoUser(hashItem.info, hashItem.dato).user
            });
            MarcarComoVisto(state, index);
        }
    } catch (e) {
        const error = e;
        MLog.print('error- Notificaciones:MostrarUsuario', e);
    }
}

export function MoverBandejaNotificacion(datos, bandeja, bot, idUser, user, red) {
    const idGenerado = db.push(`notificacion_empresa/${datos.id_empresa}/creado`, {
        bandeja,
        bot,
        create: db.timestamp,
        id: idUser,
        id_operador: user.id,
        nombre_operador: datos.nombre,
        red,
        texto: 'Nuevo usuario'
    });
}
// agregar inicialmente nuemro de usuarios a la bandeja actual
export function ContadorInicialBandeja(state, lista, ifo, bandeja) {
    // reccores lista con los usuario de l abandeja
    const usuariosBandeja = new Map();
    lista.forEach((item, i) => {
        usuariosBandeja.set(`${state.datos.id_empresa}-${ifo.red}-${ifo.bot}-${item.key}`, { ...item.val(), id: item.key });
    });
    // asignar al map
    state.hashBandejas.set(bandeja, usuariosBandeja);
}

// agregar un usuario al contador de usuarios de bandeja
export function AgregarContadorBandeja(state, item, ifo, bandeja) {
    // asignar al map
    const refBandeja = state.hashBandejas.get(bandeja);
    if (refBandeja !== undefined) {
        refBandeja.set(`${state.datos.id_empresa}-${ifo.red}-${ifo.bot}-${item.id}`, item);
    }
}
